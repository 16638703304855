import {EventEmitter, Injectable} from '@angular/core';
import {User} from './user.module';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../services/config.service';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class AuthService{
  private config:any;
  private user = new User();
  public isAuthToggle = new EventEmitter<boolean>();
  public isAuth: boolean = false;
  private autoLogoutTimer:any;
  private userCheckTimer:any;

  constructor(private http_client: HttpClient, private router: Router, private toast: ToastrService, private configService: ConfigService) {
    this.config = configService.getLocalConfig();
    let userLoaded:any = localStorage.getItem('user');
    if(userLoaded){
      userLoaded = JSON.parse(userLoaded);
      this.user = new User();
      this.user.id = (userLoaded._id)?userLoaded._id:null;
      this.user.firstName = (userLoaded._firstName)?userLoaded._firstName:null;
      this.user.lastName = (userLoaded._lastName)?userLoaded._lastName:null;
      this.user.email = (userLoaded._email)?userLoaded._email:null;
      this.user.password = (userLoaded._password)?userLoaded._password:null;
      this.user.phoneNumber = (userLoaded._phoneNumber)?userLoaded._phoneNumber:null;
      this.user.token = (userLoaded._token)?userLoaded._token:null;
      this.user.tokenExpire = (userLoaded._tokenExpire)?userLoaded._tokenExpire:null;
      this.user.permissions = (userLoaded._permissions)?userLoaded._permissions:[];

      this.isAuthToggle.subscribe((value)=>{
        this.isAuth = value;
      });

      if(this.user.token){
        this.isAuthToggle.emit(true);
      }
      else{
        this.isAuthToggle.emit(false);
      }
    }
    this.userCheck();
  }

  public getUser(){
    return this.user;
  }

  public setUser(user: User){
    this.user = user;
  }

  public createUser(user: User, reCaptchaCode: string = null){
    return this.http_client.post<any>(this.config.api + "/v1/api/security/create-user",{
      firstName: user.firstName,
      lastName: user.lastName,
      password: user.password,
      email: user.email,
      phoneNumber: user.phoneNumber,
      reCaptchaCode: reCaptchaCode
    });
  }

  public login(email: string, password:string){
    return this.http_client.post<any>(this.config.api + "/v1/api/security/login",{
      email: email,
      password: password
    }).pipe(catchError(error=>{
      return throwError(error);
    }));
  }

  public logout(){
    this.isAuthToggle.emit(false);
    this.user = null;
    this.removeUserOnStorage();
    if(this.autoLogoutTimer){
      clearTimeout(this.autoLogoutTimer);
      this.autoLogoutTimer = null;
    }
    if(this.userCheckTimer){
      clearInterval(this.userCheckTimer);
      this.userCheckTimer = null;
    }
    this.router.navigate(['/auth']);
  }

  public autoLogout(expirationDuration: number){
    this.autoLogoutTimer = setTimeout(()=>{
      this.logout();
    },expirationDuration);
  }

  public userCheck(){
    this.userCheckTimer = setInterval(()=>{
      if(this.user.id && this.user.token && this.isAuth){
        this.http_client.get<any>(this.config.api + "/v1/api/security/get-my-user")
          .subscribe(data=>{
            if(data.user){
              let userLoaded:any = localStorage.getItem('user');
              if(userLoaded){
                userLoaded = JSON.parse(userLoaded);

                //If there are not comming user from api then logout
                if(data.user && !data.user.id){
                  this.logout();
                  return;
                }

                //If tokens are equal but token expiration dates are not equal then update user on the local storage
                if(userLoaded._tokenExpire &&
                   data.user.tokenExpire &&
                   userLoaded._token &&
                   data.user.token &&
                   userLoaded._tokenExpire != data.user.tokenExpire &&
                   userLoaded._token == data.user.token)
                {
                  this.user = new User();
                  this.user.id = (userLoaded._id)?userLoaded._id:null;
                  this.user.firstName = (userLoaded._firstName)?userLoaded._firstName:null;
                  this.user.lastName = (userLoaded._lastName)?userLoaded._lastName:null;
                  this.user.email = (userLoaded._email)?userLoaded._email:null;
                  this.user.password = (userLoaded._password)?userLoaded._password:null;
                  this.user.phoneNumber = (userLoaded._phoneNumber)?userLoaded._phoneNumber:null;
                  this.user.token = (userLoaded._token)?userLoaded._token:null;
                  this.user.tokenExpire = (userLoaded._tokenExpire)?userLoaded._tokenExpire:null;

                  if(this.autoLogoutTimer){
                    clearTimeout(this.autoLogoutTimer);
                    this.autoLogoutTimer = null;
                    this.saveUserOnStorage();
                  }
                }
              }
            }
        },error=>{
            this.logout();
        });
      }
    }, 60000);
  }

  public sendVerificationCode(email: string){
    return this.http_client.post<any>(this.config.api + "/v1/api/security/send-verification-code", {
      email: email
    });
  }

  public checkVerificationCode(email: string, code:string){
    return this.http_client.post<any>(this.config.api + "/v1/api/security/check-verification-code", {
      email: email,
      code: code
    });
  }

  public removeUserOnStorage(){
    localStorage.clear();
  }

  public saveUserOnStorage(){
    if(this.user && this.user.token){
      localStorage.setItem("user", JSON.stringify(this.user));
      const time: number = +(+moment(this.user.tokenExpire) - +moment());
      this.autoLogout(time);
    }
  }

}
