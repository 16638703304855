import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stock-calculator',
  templateUrl: './stock-calculator.component.html',
  styleUrls: ['./stock-calculator.component.css']
})
export class StockCalculatorComponent implements OnInit {

  capital: any;
  risk: any;
  buy_price: any;
  resistance_price: any;
  support_price: any;
  sell_price: any;
  stock_qty: any;
  investment: any;
  readyForCalculate: boolean;
  investment_type: string;
  errorMessage: string;

  constructor() {
    this.resetForm();
    this.investment_type = "long";
    this.readyForCalculate = false;
  }

  ngOnInit() {}

  public showRadio(value: string){
    this.investment_type = value;
  }

  calculate(){
    this.readyForCalculate = false;
    if(this.capital && this.risk && this.resistance_price && this.support_price){
      if(this.investment_type == "long")
        this.calculateLong();
      else{
        this.calculateShort();
      }
    }
    else{
      this.readyForCalculate = false;
    }
  }

  calculateLong(){
    if(+this.resistance_price <= +this.support_price){
      this.errorMessage = "The resistance cannot be less than the support.";
      return;
    }
    if(+this.risk <= 0 || +this.risk > 100){
      this.errorMessage = "Risk must be greater than zero and less than equal to 100.";
      return;
    }
    this.stock_qty =  Math.floor((+this.capital*+this.risk/100)/(+this.resistance_price-+this.support_price));
    this.buy_price = (+this.resistance_price + 0.01);
    this.sell_price = +this.support_price - (3*(+this.support_price/100));
    this.investment = +this.stock_qty * +this.buy_price;
    this.readyForCalculate = true;
  }

  calculateShort(){
    if(+this.resistance_price <= +this.support_price){
      this.errorMessage = "The resistance cannot be less than the support.";
      return;
    }
    if(+this.risk <= 0 || +this.risk > 100){
      this.errorMessage = "Risk must be greater than zero and less than equal to 100.";
      return;
    }
    this.stock_qty =  Math.floor((+this.capital*+this.risk/100)/(+this.resistance_price-+this.support_price));
    this.buy_price = +this.resistance_price + (3*(+this.resistance_price/100));
    this.sell_price = +this.support_price - 0.01;
    this.investment = +this.stock_qty * +this.sell_price;
    this.readyForCalculate = true;
  }

  investmentTypeToggle(){

  }

  resetForm(){
    this.risk = "";
    this.capital = "";
    this.support_price = "";
    this.resistance_price = "";
    this.stock_qty = "";
    this.investment = "";
    this.buy_price = "";
    this.sell_price = "";
    this.readyForCalculate = false;
  }

}
