import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {OpportunitySeekerFilterService} from './opportunity-seeker-filter-service';
import {Subscription} from 'rxjs';
import {ModalService} from '../../../common/modal/modal.service';

@Component({
  selector: 'opportunity-seeker-filter',
  templateUrl: './opportunity-seeker-filter.component.html',
  styleUrls: ['./opportunity-seeker-filter.component.css']
})
export class OpportunitySeekerFilterComponent implements OnInit, OnDestroy {

  @Input() showModal: boolean = false;
  @Output() showModalChange = new EventEmitter<boolean>();
  @Input() investmentType: string = 'long';
  @Output() investmentTypeChange = new EventEmitter<string>();
  @Input() data: any = [];
  @Output() dataChange = new EventEmitter<boolean>();
  @Input() symbol: any;
  @Output() symbolChange = new EventEmitter<string>();
  @Input() showOnlyQEC = false;
  @Output() showOnlyQECChange = new EventEmitter<boolean>();
  @Input() capital: any = '';
  @Output() capitalChange = new EventEmitter<string>();
  @Input() risk: any = '';
  @Output() riskChange = new EventEmitter<string>();
  @Input() isExpanded = false;
  @Output() isExpandedChange = new EventEmitter<boolean>();
  @Input() yearIPO = "All";
  @Output() yearIPOChange = new EventEmitter<string>();
  @Input() yearIterator:any = [];
  @Output() yearIteratorChange = new EventEmitter<[]>();
  @Input() assesType:any = [];
  @Output() assesTypeChange = new EventEmitter<[]>();

  //Subscription
  showModalSubscription: Subscription;

  constructor(private opportunitySeekerFilterService:OpportunitySeekerFilterService,
              private modalService: ModalService) { }

  ngOnInit() {
    this.showModalSubscription = this.modalService.showModal.subscribe(value=>{
      this.showModal = value;
      this.showModalChange.emit(this.showModal)
    });
  }

  ngOnDestroy(): void {
    this.showModalSubscription.unsubscribe();
  }

  proposeRisk(){

  }

  requestOpportunityDataToApi(){
    this.showOnlyQECChange.emit(this.showOnlyQEC);
    this.investmentTypeChange.emit(this.investmentType);
    this.symbolChange.emit(this.symbol);
    this.capitalChange.emit(this.capital);
    this.riskChange.emit(this.risk);
    this.isExpandedChange.emit(this.isExpanded);
    this.yearIPOChange.emit(this.yearIPO);
    this.yearIteratorChange.emit(this.yearIterator);
    this.assesTypeChange.emit(this.assesType);
    this.opportunitySeekerFilterService.executeFilter.emit();
  }

  resetRisk(){
    this.risk = "";
    this.data = [];
  }

  resetCapital(){
    this.capital = "";
    this.data = [];
  }

  resetSymbol(){
    this.symbol = "";
    this.data = [];
  }

  resetFilters(){
    this.resetRisk();
    this.resetCapital();
    this.resetSymbol();
  }

  public areSomeAssetTypeSelected(){
    let isSelected =false;
    for (let item of this.assesType){
      if(item.isSelected){
        isSelected =  true;
      }
    }
    return isSelected;
  }

}
