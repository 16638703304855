import {Injectable} from '@angular/core';
import {AuthService} from '../app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  userPermissions = [];

  constructor(private authService: AuthService) {
    this.userPermissions = this.authService.getUser().permissions;
  }

  public checkPermissionByNameKey(nameKey:string){
    for(let item of this.userPermissions){
      if(item.nameKey == nameKey){
        return true;
      }
    }
    return false;
  }
}
