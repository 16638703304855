import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';
import {SpinnerService} from '../../../services/spinner.service';
import {isDefined} from '@ng-bootstrap/ng-bootstrap/util/util';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']
})
export class MemberComponent implements OnInit {

  showModal: boolean = false;
  userList = [];
  userSelected : { firstName: string, lastName: string, email: string, id: string, fullName: string, phoneNumber: string };
  memberList = [];
  member: { firstName: string, lastName: string, email: string, phone: string, userId: string, user:any, status:any };
  showUserDropdown = false;
  inEditMode = false;

  constructor(private httpClient: HttpClient,
              private configService: ConfigService,
              private spinnerService: SpinnerService) {
    this.userSelected = {firstName: "", lastName: "", email: "", id: "", fullName: "", phoneNumber: ""};
    this.member = {firstName: "", lastName: "", email: "", phone: "", userId: "", user:null, status:""};
  }


  ngOnInit() {
    this.findMembers();
  }

  public findUserByKeyWord(keyWord: string){
    if(keyWord.length==0){
      this.cleanSelectedUser();
      this.member.userId = "";
    }
    if(keyWord.length>=3){
      this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/user/" + keyWord).subscribe((data:any)=>{
        this.userList = data;
        this.showUserDropdown = true;
      },error => {

      });
    }
  }

  public findMembers(){
    this.spinnerService.showSpinner.emit(true);
    this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/member/findAllMembers").subscribe((data:any)=>{
      this.memberList = data;
      for(let item of this.memberList){
        item.status = (item.status == "ACTIVATED"?true:false);
      }
      this.spinnerService.showSpinner.emit(false);
    }, error => {
      this.spinnerService.showSpinner.emit(false);
    });
  }

  public addMember(form: NgForm){
    this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/member/create",this.member).subscribe((data:any)=>{
      this.closeModal();
      this.findMembers();
    }, error => {
      this.findMembers();
    });
  }

  public editMember(form: NgForm){
    this.member.userId = ((typeof this.userSelected.id !== 'undefined' && this.userSelected.id != "")?this.userSelected.id:null);
    this.member.status = ((this.member.status == false)?"INACTIVATED":"ACTIVATED");
    this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/member/update",this.member).subscribe((data:any)=>{
      this.closeModal();
      this.findMembers();
    }, error => {
      this.findMembers();
    });
  }

  public closeModal(){
    this.showModal = false;
    this.userList = [];
    this.cleanMember();
    this.cleanSelectedUser();
  }

  public cleanSelectedUser(){
    this.userSelected = {firstName: "", lastName: "", email: "", id: "", fullName: "", phoneNumber: ""};
  }

  public cleanMember(){
    this.member = {firstName: "", lastName: "", email: "", phone: "", userId: "", user:null, status:""};
  }

  public toggleActiveMember(member: { id: string, firstName: string, lastName: string, email: string, phone: string, userId: string, status: any }){
    member.status = (member.status == false?"INACTIVATED":"ACTIVATED");
    this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/member/toggleStatus", {
      id: member.id,
      status: member.status
    }).subscribe((data:any)=>{

    }, error => {

    });
  }

  public showCreateMemberModal(){
    this.inEditMode = false;
    this.showModal = true;
  }

  public showEditMemberModal(member){
    this.showModal = true;
    this.member = member;
    this.userSelected = ((member.user != null)?member.user:this.userSelected);
    this.inEditMode = true;
  }

  public deleteMember(memberId){
    this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/member/delete/" + memberId).subscribe((data:any)=>{
      this.findMembers();
    }, error => {

    });
  }

}
