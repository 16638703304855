import {Component, Input, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'simple-spinner',
  templateUrl: './simple-spinner.component.html',
  styleUrls: ['./simple-spinner.component.css']
})
export class SimpleSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
