import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ModalService} from './modal.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit, OnDestroy {

  @Input() title: string = "My Modal";
  @Input() icon: string = "fa-signal";
  @Input() showModal: boolean = false;
  @Output() showModalChange = new EventEmitter<boolean>();
  @Input() modalType: string = "modal-small";
  @Input() height: string = null;

  //Subscription
  showModalSubscription: Subscription;
  iconSubscription: Subscription;
  titleSubscription: Subscription;
  modalTypeSubscription: Subscription


  constructor(public modalService: ModalService) {

  }

  ngOnInit() {
    this.showModalSubscription = this.modalService.showModal.subscribe(value=>{
      this.showModal = value;
      this.showModalChange.emit(this.showModal)
    });

    this.iconSubscription = this.modalService.icon.subscribe(value=>{
      this.icon = value;
    });

    this.titleSubscription = this.modalService.title.subscribe(value=>{
      this.title = value;
    });

    this.modalTypeSubscription = this.modalService.modalType.subscribe(value=>{
      this.modalType = value;
    });
  }

  ngOnDestroy() {
    this.showModalSubscription.unsubscribe();
    this.iconSubscription.unsubscribe();
    this.titleSubscription.unsubscribe();
    this.modalTypeSubscription.unsubscribe();

  }

  onClose(){
    this.showModalSubscription.unsubscribe();
    this.iconSubscription.unsubscribe();
    this.titleSubscription.unsubscribe();
    this.modalTypeSubscription.unsubscribe();
    this.title = "My Modal";
    this.icon = "fa-signal";
    this.showModal = false;
    this.showModalChange = new EventEmitter<boolean>();
    this.modalType = "modal-small";
    this.height = null;
    this.modalService.showModal.emit(false);
  }

}
