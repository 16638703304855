import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalService} from '../../common/modal/modal.service';

@Component({
  selector: 'email-address-modal',
  templateUrl: './email-address-modal.component.html',
  styleUrls: ['./email-address-modal.component.css']
})
export class EmailAddressModalComponent implements OnInit {

  @Input()  ownEmailAddress;
  @Input()  emailAddressList:any = [];
  @Output() emailAddressListChange = new EventEmitter<any>();
  @Input()  comment:string;
  @Output() commentChange = new EventEmitter<string>();

  newEmail = "";

  constructor(private modal: ModalService) { }

  ngOnInit() {
    if (!this.checkIfEmailExists(this.ownEmailAddress) && this.validateEmail(this.ownEmailAddress))
      this.emailAddressList.push(this.ownEmailAddress);
  }

  public addEmailAddress(emailAddress){
    if(!this.checkIfEmailExists(emailAddress) && this.validateEmail(emailAddress)){
      this.emailAddressList.push(emailAddress);
      this.newEmail = "";
    }
  }

  public removeEmailAddress(emailAddress){
    this.removeEmailAddressFromList(emailAddress);
  }

  public checkIfEmailExists(emailAddress){
    for(let email of this.emailAddressList){
      if(email.toLowerCase()==emailAddress.toLowerCase())
        return true;
    }
    return false;
  }

  public removeEmailAddressFromList(emailAddress){
    let index = this.emailAddressList.indexOf(emailAddress);
    if(index > -1)
      this.emailAddressList.splice(this.emailAddressList.indexOf(emailAddress),1);
  }

  public validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
  }

}
