import {Component, DoCheck, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalService} from '../modal/modal.service';
import {GraphService} from '../../graph/graph.service';
import * as moment from 'moment';
import {ConfigService} from '../../../services/config.service';
import {OpportunitySeekerService} from '../../tools/opportunity-seeker/opportunity-seeker.service';
import {TableService} from './table.service';

@Component({
  selector: 'myTable',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {

  selectedButton: number = 1;
  labelButton1 = 1;
  labelButton2 = 2;
  labelButton3 = 3;
  labelButton4 = 4;
  labelButton5 = 5;
  @Input() height = ''
  @Input() column = [];
  @Input() data: [] = [];
  @Input() paginatorStart: number;
  @Output() paginatorStartChange = new EventEmitter();
  @Input() paginatorCount: number;
  @Output() paginatorCountChange = new EventEmitter();
  @Input() total: number;

  constructor(private modalService: ModalService,
              private graphService: GraphService,
              private configService: ConfigService,
              private opportunitySeekerService: OpportunitySeekerService,
              private tableService: TableService) { }

  ngOnInit() {}

  public showGraph(position: number){
    this.opportunitySeekerService.showingGraphPosition.emit(position);
  }

  public goToPrevious(){
    this.paginatorStart = this.paginatorStart-this.paginatorCount;
    if(this.selectedButton == 1){
      this.labelButton1 -= 5;
      this.labelButton2 -= 5;
      this.labelButton3 -= 5;
      this.labelButton4 -= 5;
      this.labelButton5 -= 5;
      this.selectedButton = 5;
    }
    else{
      this.selectedButton -= 1;
    }
    this.paginatorStartChange.emit(this.paginatorStart);
    this.requestDataToApi();
  }

  public goToNext(){
    this.paginatorStart = this.paginatorStart+this.paginatorCount;
    if(this.selectedButton == 5){
      this.labelButton1 += 5;
      this.labelButton2 += 5;
      this.labelButton3 += 5;
      this.labelButton4 += 5;
      this.labelButton5 += 5;
      this.selectedButton = 1;
    }
    else{
      this.selectedButton += 1;
    }
    this.paginatorStartChange.emit(this.paginatorStart);
    this.requestDataToApi()
  }

  public requestDataToApi(){
    this.tableService.requestData.emit();
  }
}
