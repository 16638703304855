export class Member {

  private _id?: string;
  private _firstName?: string;
  private _lastName?: string;
  private _email?: string;
  private _phone?: string;
  private _userId?: string;
  private _status?: string;
  private _created?: string;
  private _createdTimezone?: string;
  private _updated?: string;
  private _updatedTimezone?: string;
  private _deleted?: string;
  private _deletedTimezone?: string;

  constructor() {}

  public fromArray(memberArray){
    this.id = (memberArray['id'] !== 'undefined' && memberArray['id']!="" || memberArray['id']!=null)?memberArray['id']:null;
    this.firstName = (memberArray['firstName'] !== 'undefined' && memberArray['firstName']!="" || memberArray['firstName']!=null)?memberArray['firstName']:null;
    this.lastName = (memberArray['lastName'] !== 'undefined' && memberArray['lastName']!="" || memberArray['lastName']!=null)?memberArray['lastName']:null;
    this.email = (memberArray['email'] !== 'undefined' && memberArray['email']!="" || memberArray['email']!=null)?memberArray['email']:null;
    this.phone = (memberArray['phone'] !== 'undefined' && memberArray['phone']!="" || memberArray['phone']!=null)?memberArray['phone']:null;
    this.userId = (memberArray['userId'] !== 'undefined' && memberArray['userId']!="" || memberArray['userId']!=null)?memberArray['userId']:null;
    this.status = (memberArray['status'] !== 'undefined' && memberArray['status']!="" || memberArray['status']!=null)?memberArray['status']:null;
    this.created = (memberArray['created'] !== 'undefined' && memberArray['created']!="" || memberArray['created']!=null)?memberArray['created']:null;
    this.createdTimezone = (memberArray['createdTimezone'] !== 'undefined' && memberArray['createdTimezone']!="" || memberArray['createdTimezone']!=null)?memberArray['createdTimezone']:null;
    this.updated = (memberArray['updated'] !== 'undefined' && memberArray['updated']!="" || memberArray['updated']!=null)?memberArray['updated']:null;
    this.updatedTimezone = (memberArray['updatedTimezone'] !== 'undefined' && memberArray['updatedTimezone']!="" || memberArray['updatedTimezone']!=null)?memberArray['updatedTimezone']:null;
    this.deleted = (memberArray['deleted'] !== 'undefined' && memberArray['deleted']!="" || memberArray['deleted']!=null)?memberArray['deleted']:null;
    this.deletedTimezone = (memberArray['deletedTimezone'] !== 'undefined' && memberArray['deletedTimezone']!="" || memberArray['deletedTimezone']!=null)?memberArray['deletedTimezone']:null;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get phone(): string {
    return this._phone;
  }

  set phone(value: string) {
    this._phone = value;
  }

  get userId(): string {
    return this._userId;
  }

  set userId(value: string) {
    this._userId = value;
  }

  get status(): string {
    return this._status;
  }

  set status(value: string) {
    this._status = value;
  }

  get created(): string {
    return this._created;
  }

  set created(value: string) {
    this._created = value;
  }

  get createdTimezone(): string {
    return this._createdTimezone;
  }

  set createdTimezone(value: string) {
    this._createdTimezone = value;
  }

  get updated(): string {
    return this._updated;
  }

  set updated(value: string) {
    this._updated = value;
  }

  get updatedTimezone(): string {
    return this._updatedTimezone;
  }

  set updatedTimezone(value: string) {
    this._updatedTimezone = value;
  }

  get deleted(): string {
    return this._deleted;
  }

  set deleted(value: string) {
    this._deleted = value;
  }

  get deletedTimezone(): string {
    return this._deletedTimezone;
  }

  set deletedTimezone(value: string) {
    this._deletedTimezone = value;
  }

}
