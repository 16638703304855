import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';
import {GraphModalService} from '../../common/graph-modal/graph-modal.service';
import * as moment from "moment";

@Component({
  selector: 'new-ipo',
  templateUrl: './new-ipo.component.html',
  styleUrls: ['./new-ipo.component.css']
})
export class NewIpoComponent implements OnInit {

  @Input() limit: string = '10';
  @Input() buttonSizeClass = "btn-xs";
  isExpanded: boolean = false;
  searchCompleted: boolean = false;
  currentYear = moment().format("YYYY");
  oneYearAgo = moment().subtract(1, "year").format("YYYY");
  twoYearAgo = moment().subtract(2, "year").format("YYYY");
  @Input() timing = this.currentYear;
  assetTypeList: any = ['All Type', 'EQUITY', 'ETF'];
  selectedAssetType: string = "All Type";
  isExpandedAssetType: boolean = false;

  ipoList = [];

  constructor(private httpClient: HttpClient,
              private configService: ConfigService,
              private graphModalService: GraphModalService) { }

  ngOnInit() {
    this.getNewIPO();
  }

  public getNewIPO(){
    this.ipoList = [];
    this.searchCompleted = false;
    this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/stock/get-new-ipo/",{
      'assetType': this.selectedAssetType,
      'limit': this.limit,
      'timing': this.timing
    }).subscribe((data:any)=>{
      this.ipoList = data;
      this.searchCompleted = true;
    }, error => {

    });
  }

  public showGraph(item){
    this.graphModalService.openModal('Graph', 'fa-signal', item.code, 'MONTH');
  }

}
