import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import HAccessibility from "highcharts/modules/accessibility";
import * as Highcharts from 'highcharts';
import {AccountService} from '../../../account/account.service';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../../services/config.service';
import * as moment from "moment";
import {InstrumentComparatorGraphService} from './instrument-comparator-graph.service';
import {Subscription} from 'rxjs';

HAccessibility(Highcharts);

@Component({
  selector: 'app-instrument-comparator-graph',
  templateUrl: './instrument-comparator-graph.component.html',
  styleUrls: ['./instrument-comparator-graph.component.css']
})
export class InstrumentComparatorGraphComponent implements OnInit, OnDestroy {

  Highcharts: typeof Highcharts = Highcharts;
  myChart:any = null;

  @Input() instrumentBase:{id:string, code:string, name:string} = {id: "defaultAccount", code: "defaultAccount", name: "defaultAccount"};
  @Input() instrumentSecondaries:{code:string, name:string}[] = null;
  @Input() timing:any = "_1M";
  @Input() fromDate = null;
  @Input() toDate = null;

  //Subscription
  reloadGraphSubscription: Subscription;
  instrumentBaseSubscription: Subscription;
  instrumentSecondariesSubscription: Subscription;

  constructor(private accountService: AccountService,
              private httpClient: HttpClient,
              private configService: ConfigService,
              private reloadGraphService: InstrumentComparatorGraphService) { }

  ngOnInit() {
    this.initGraph();
    this.loadInstrumentBaseData();
    this.instrumentBaseSubscription = this.reloadGraphService.instrumentBase.subscribe(value=>{
      this.instrumentBase = value;
    });
    this.instrumentSecondariesSubscription = this.reloadGraphService.instrumentSecondaries.subscribe(value=>{
      this.instrumentSecondaries = value;
    });
    this.reloadGraphSubscription = this.reloadGraphService.reloadGraph.subscribe(value=>{
      this.reloadGraph();
    })
  }

  ngOnDestroy(): void {
    this.instrumentBaseSubscription.unsubscribe();
    this.instrumentSecondariesSubscription.unsubscribe();
    this.reloadGraphSubscription.unsubscribe();
  }

  public reloadGraph(){
    this.destroyGraph();
    this.initGraph();
    this.loadInstrumentBaseData();
  }

  public initGraph(){
    let options = this.getChartOptions();
     this.myChart = this.Highcharts.chart('instrumentComparatorGraph',options);
  }

  public destroyGraph(){
    this.myChart.destroy();
  }

  public loadInstrumentBaseData(){
    if(this.instrumentBase!=null && this.instrumentBase.code == "defaultAccount"){
      this.loadDefaultAccount();
    }
    else if(this.instrumentBase!=null){
      this.httpClient.post(this.configService.getLocalConfig().api +
        "/v1/api/stock/find-instrument-historic-in-percentage", {
        "instrumentCode": this.instrumentBase.code,
        "timing": this.timing,
        "fromDate": this.fromDate,
        "toDate": this.toDate
      }).subscribe((data:any)=>{
        this.setSeries(data, this.instrumentBase.name);
        this.loadInstrumentSecondaryData();
      }, error => {

      });
    }
  }

  public loadInstrumentSecondaryData(){
    if(this.instrumentSecondaries && this.instrumentSecondaries.length > 0){
      for(let item of this.instrumentSecondaries){
        this.httpClient.post(this.configService.getLocalConfig().api +
          "/v1/api/stock/find-instrument-historic-in-percentage", {
          "instrumentCode": item.code,
          "timing": this.timing,
          "fromDate": this.fromDate,
          "toDate": this.toDate
        }).subscribe((data:any)=>{
          this.setSeries(data, item.name)
        }, error => {

        });
      }
    }
  }

  public getChartOptions(){
    let options:any = {
      chart: {
        type: 'line',
        zoomType: 'x',
        // height: this.graphHeight
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        height: '100%',
        title: {
          text: 'Percentage(%)'
        },
      },
      tooltip: {
        pointFormat: '{series.name} <b>{point.y}%</b>'
      },
      plotOptions: {
        area: {
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      series: []
    };
    return options;
  }

  public setSeries(data, name){
    this.myChart.addSeries({
      name: name,
      data: data
    });
  }

  public loadDefaultAccount(){
    this.accountService.getMyDefaultAccount().subscribe((data:any)=>{
      if(data && data['id']){
        this.httpClient.get(this.configService.getLocalConfig().api +
          "/v1/api/account/get-wallet-historic-by-account-percentage/" +
          data['id'] + "/" + this.fromDate + "/" + this.toDate + "/" + this.timing).subscribe((response:any)=>{
            if(response){
              this.setSeries(response, "My Account");
              if(response.length > 0){
                this.fromDate = moment(response[0][0]).format("YYYY-MM-DD HH:mm:ss");
                this.toDate = moment(response[(response.length-1)][0]).format("YYYY-MM-DD HH:mm:ss");
              }
              this.loadInstrumentSecondaryData();
            }
        }, error => {

        });
      }
    },error => {

    });
  }

}
