import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';
import {ConfigService} from '../services/config.service';

let configService: ConfigService = new ConfigService();
let config = configService.getLocalConfig();

export const myRxStompConfig: InjectableRxStompConfig = {

  // brokerURL: 'wss://' + config.pushNotification.host + ':' + config.pushNotification.port +'/wss',
  //
  // connectHeaders: {
  //   login: config.pushNotification.user,
  //   passcode: config.pushNotification.password,
  // },
  //
  // heartbeatIncoming: 0,
  // heartbeatOutgoing: 20000,
  //
  // reconnectDelay: 200,
  //
  // debug: (msg: string): void => {
  //   //console.log(new Date(), msg);
  // },
};
