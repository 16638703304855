import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener, OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {GraphService} from '../graph/graph.service';
import {CompanySearchService} from '../common/company-search/company-search.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit, AfterViewInit, OnDestroy{
  @ViewChild('graph', {static: false}) graph: ElementRef;
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event) {
    this.isFullscreen = !this.isFullscreen;
    this.graphService.reDraw.emit();
  }
  isFullscreen: boolean = false;

  constructor(private graphService: GraphService, private companySearchService: CompanySearchService) {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.companySearchService.searchCriteria = null;
  }


  ngAfterViewInit(): void {
    if(this.companySearchService.searchCriteria != null){
      this.graphService.ticket.emit(this.companySearchService.searchCriteria.code);
      this.graphService.timing.emit(this.companySearchService.searchCriteria.timing);
      this.graphService.startDate.emit(this.companySearchService.searchCriteria.startDate);
      this.graphService.endDate.emit(this.companySearchService.searchCriteria.endDate);
    }
  }

  fullScreen(){
    if(!this.isFullscreen){
      if (this.graph.nativeElement.requestFullscreen) {
        this.graph.nativeElement.requestFullscreen();
      } else if (this.graph.nativeElement.mozRequestFullScreen) { /* Firefox */
        this.graph.nativeElement.mozRequestFullScreen();
      } else if (this.graph.nativeElement.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        this.graph.nativeElement.webkitRequestFullscreen();
      } else if (this.graph.nativeElement.msRequestFullscreen) { /* IE/Edge */
        this.graph.nativeElement.msRequestFullscreen();
      }
    }
    else {
      if (this.graph.nativeElement.exitFullscreen) {
        this.graph.nativeElement.exitFullscreen();
      } else if (this.graph.nativeElement.mozCancelFullScreen) {
        /* Firefox */
        this.graph.nativeElement.mozCancelFullScreen();
      } else if (this.graph.nativeElement.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.graph.nativeElement.webkitExitFullscreen();
      } else if (this.graph.nativeElement.msExitFullscreen) {
        /* IE/Edge */
        this.graph.nativeElement.msExitFullscreen();
      }
    }
    this.graphService.reDraw.emit();
  }

}
