import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ConfigService} from '../../services/config.service';
import {GraphService} from './graph.service';
import {SpinnerService} from '../../services/spinner.service';
import {isNull, isUndefined} from 'util';
import {AuthService} from '../auth/auth.service';
import * as Highcharts from "highcharts/highstock";
import HIndicatorsAll from "highcharts/indicators/indicators-all";
import HDragPanes from "highcharts/modules/drag-panes";
import HAnnotationsAdvanced from "highcharts/modules/annotations-advanced";
import HPriceIndicator from "highcharts/modules/price-indicator";
import HStockTools from "highcharts/modules/stock-tools";
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import * as _ from 'lodash';

HIndicatorsAll(Highcharts);
HDragPanes(Highcharts);
HAnnotationsAdvanced(Highcharts);
HPriceIndicator(Highcharts);
HStockTools(Highcharts);


@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css']
})
export class GraphComponent implements OnInit, OnDestroy {
  Highcharts: typeof Highcharts = Highcharts;
  chart: any;
  cloneChart: any = {};
  data = [];
  @Input() showFullSpinner : true = true;
  isFindingData: boolean = true;
  @Input() ticket:string;
  config
    :any;
  option:any;
  workspace: any = {};
  @Input() loadWorkspace: boolean = false;
  @Input() saveWorkspace: boolean = false;
  workspaceTimeInterval: any;
  body:any;
  @Input() stockToolButtons = [];
  @Input() startDate: any;
  @Input() endDate: any;
  @Input() showGUI: boolean = false;
  @Input() showRangeSelector: boolean = false;
  @Input() barType: string = "ohlc";
  @Input() scrollBar: boolean = false;
  ohlc:any = [];
  volume:any = [];
  company: any;
  @Input() scale:string = "logarithmic";
  @Input() timing:string = '_MONTH';
  //Subscriptions
  timingSubscription: Subscription;
  ticketSubscription: Subscription;
  startDateSubscription: Subscription;
  endDateSubscription: Subscription;
  cleanGraphSubscription: Subscription;
  requestSubscription: Subscription;
  removeResistanceSubscription: Subscription;
  removeSupportSubscription: Subscription;
  reDrawSubscription: Subscription;

  constructor(private httpClient: HttpClient,
              private graphService: GraphService,
              private spinnerService: SpinnerService,
              private authService: AuthService,
              private toast: ToastrService, private configService: ConfigService) {
    this.config = configService.getLocalConfig();
    this.timingSubscription = this.graphService.timing.subscribe((value)=>{
      this.timing = value;
    });
  }

  public drawResistance(){
    if(this.graphService.drawResistance.observers.length > 0)
      return;
    this.graphService.drawResistance.subscribe((orden)=>{
      try {
        if(this.graphService.investment_type == 'short'){
          this.chart.yAxis[0].removePlotLine("stop-loss-id");
        }
        this.chart.yAxis[0].removePlotLine("resistance-id");
      }
      catch (e) {
        //
      }
      let referenceA = this;
      if(orden == 0){
        //Drawing resistance line
        if(isUndefined(this.option.chart.events.click) || isNull(this.option.chart.events.click)){
          referenceA.option.chart.events.click = function(event) {
            referenceA.drawHorizonalLine('resistance-id', event.yAxis[0].value, 'blue', 1,'Solid');
            if(referenceA.graphService.investment_type == 'short'){
              let stopLoss = event.yAxis[0].value+(event.yAxis[0].value*3/100);
              //Drawing stop loss line
              referenceA.drawHorizonalLine('stop-loss-id', stopLoss, 'green', 1, 'ShortDash');
            }
            referenceA.graphService.resistance.emit(event.yAxis[0].value);
            referenceA.option.chart.events = {};
            referenceA.chart = referenceA.Highcharts.stockChart('container', referenceA.option);
          };
          referenceA.chart = referenceA.Highcharts.stockChart('container', referenceA.option);
        }
      }
      else {
        this.drawHorizonalLine('resistance-id', orden, 'blue', 1,'Solid');
        if(this.graphService.investment_type == 'short'){
          let stopLoss = orden+(orden*3/100);
          //Drawing stop loss line
          this.drawHorizonalLine('stop-loss-id', stopLoss, 'green', 1, 'ShortDash');
        }
      }
    });
  }

  public drawSupport(){
    if(this.graphService.drawSupport.observers.length > 0)
      return;
    this.graphService.drawSupport.subscribe((orden)=>{
      try{
        if(this.graphService.investment_type == 'long'){
          this.chart.yAxis[0].removePlotLine("stop-loss-id");
        }
        this.chart.yAxis[0].removePlotLine("support-id");
      }
      catch (e) {
        //
      }
      let referenceA = this;
      if(orden == 0){
        //Drawing resistance line
        if(isUndefined(this.option.chart.events.click) || isNull(this.option.chart.events.click)){
          referenceA.option.chart.events.click = function(event) {
            //Drawing support line
            referenceA.drawHorizonalLine('support-id', event.yAxis[0].value, 'red', 1, 'Solid');
            if(referenceA.graphService.investment_type == 'long'){
              let stopLoss = event.yAxis[0].value-(event.yAxis[0].value*3/100);
              //Drawing stop loss line
              referenceA.drawHorizonalLine('stop-loss-id', stopLoss, 'green', 1, 'ShortDash');
            }
            referenceA.option.chart.events = {};
            referenceA.graphService.support.emit(event.yAxis[0].value);
            referenceA.chart = referenceA.Highcharts.stockChart('container', referenceA.option);
          };
          referenceA.chart = referenceA.Highcharts.stockChart('container', referenceA.option);
        }
      }
      else {
        //Drawing support line
        this.drawHorizonalLine('support-id', orden, 'red', 1, 'Solid');
        if(this.graphService.investment_type == 'long'){
          let stopLoss = orden-(orden*3/100);
          //Drawing stop loss line
          this.drawHorizonalLine('stop-loss-id', stopLoss, 'green', 1, 'ShortDash');
        }
      }
    });
  }

  public drawHorizonalLine(id:string, value:number, color:string, width:number, dashStyle){
    if(this.option.yAxis){
      if(!this.option.yAxis[0].plotLines)
        this.option.yAxis[0].plotLines = [];
      this.option.yAxis[0].plotLines.push({
        id: id,
        color: color,
        value: value,
        width:width,
        dashStyle: dashStyle,
        events: {
          click: function (event) {

          },
          mouseover: function (event) {

          },
          mouseout: function (event) {

          },
          mousemove: function(event){

          }
        }
      });
      this.chart = this.Highcharts.stockChart('container', this.option,(value)=>{

      });
    }
  }

  public removeResistance(){
    this.removeResistanceSubscription = this.graphService.removeResistance.subscribe((value)=>{
      if(value && this.chart && this.chart.yAxis)
        this.chart.yAxis[0].removePlotLine("resistance-id");
    });
  }

  public removeSupport(){
    this.removeSupportSubscription = this.graphService.removeSupport.subscribe((value)=>{
      if(value && this.chart && this.chart.yAxis)
        this.chart.yAxis[0].removePlotLine("support-id");
      if(this.chart && this.chart.yAxis)
        this.chart.yAxis[0].removePlotLine("stop-loss-id");
    });
  }

  public setCompany(){
    return this.httpClient.post(this.config.api + '/v1/api/stock/fundamental',
      {
        token: this.authService.getUser().token,
        symbol: this.ticket
      });
  }

  public setBody(){
    this.body = {
      token: this.authService.getUser().token,
      symbol: this.ticket,
      start: this.startDate,
      end: this.endDate,
      timing: this.timing
    };
  }

  public ngOnInit() {
    this.setSubscriptions();
    if(this.saveWorkspace){
      let instanceA = this;
      this.workspaceTimeInterval = setInterval(function(){
        instanceA.saveWorkspaceFunction();
      }, 10000);
    }
  }

  public setOptions(){
    const that = this;
    this.option = {
      chart: {
        renderTo: 'container',
        events: {
          load: function(e){
            let plOpen = (100 * (e.target.series[0].points[e.target.series[0].points.length-1].close - e.target.series[0].points[0].open) / e.target.series[0].points[0].open).toFixed(2);
            e.target.setTitle(null,
              { text: that.company?that.company.assetType +
                  " | " + that.company.code +
                  " | " + that.company.marketCap + "M" +
                  " | <b>PL Open: </b>" + plOpen  +"%": ""});
          },
          redraw: function (e) {

          }
        }
      },
      stockTools: {
        gui: {
          enabled: this.showGUI,
          buttons : this.stockToolButtons
        }
      },
      plotOptions: {
        series: {
          dragDrop: {
            draggableY: true
          }
        },
        line: {
          cursor: 'ns-resize'
        }
      },
      yAxis:[{
        labels: {
          align: 'left'
        },
        crosshair: {
          snap: false,
          label: {
            enabled: true,
            format: '{value:.2f}'
          }
        },
        height: '100%',
        resize: {
          enabled: true
        }
      }],
      xAxis: {
        events:{
          afterSetExtremes: function(e){
            let plOpen = (100 * (e.target.series[0].points[e.target.series[0].points.length-1].close - e.target.series[0].points[0].open) / e.target.series[0].points[0].open).toFixed(2);
            this.chart.setTitle(null,
              { text: that.company?that.company.assetType +
                                    " | " + that.company.code +
                                    " | " + that.company.marketCap + "M" +
                                    " | <b>PL Open: </b>" + plOpen  +"%": ""});
          }
        },
      },
      title: {
        text: (this.company)?this.company.name:this.ticket
      },
      rangeSelector:{
        enabled: this.showRangeSelector
      },
      subtitle: {
        text: this.company?this.company.assetType + " | " + this.company.code + " | " + this.company.marketCap + "M":""
      },
      scrollbar: {
        enabled: this.scrollBar
      },
      tooltip: {
        shape: 'square',
        headerShape: 'callout',
        borderWidth: 0,
        shadow: true,
        positioner: function (width, height, point) {
          let chart = this.chart,
            position;

          if (point.isHeader) {
            position = {
              x: Math.max(
                // Left side limit
                chart.plotLeft,
                Math.min(
                  point.plotX + chart.plotLeft - width / 2,
                  // Right side limit
                  chart.chartWidth - width - chart.marginRight
                )
              ),
              y: point.plotY
            };
          } else {
            position = {
              x: point.series.chart.plotLeft,
              y: point.series.yAxis.top - chart.plotTop
            };
          }
          return position;
        }
      }
    };
  }

  public setSubscriptions(){
    this.ticketSubscription = this.graphService.ticket.subscribe((value)=>{
      this.ticket = value;
      this.requestAndMappingData();
    });
    this.startDateSubscription = this.graphService.startDate.subscribe((data)=>{
      this.startDate = data;
      this.requestAndMappingData();
    });
    this.endDateSubscription = this.graphService.endDate.subscribe((data)=>{
      this.endDate = data;
      this.requestAndMappingData();
    });
    this.cleanGraphSubscription = this.graphService.cleanGraph.subscribe(()=>{
      this.cleanGraph();
    });
    this.reDrawSubscription = this.graphService.reDraw.subscribe(()=>{
      this.chart = this.Highcharts.stockChart('container', this.option);
    });
  }

  public requestAndMappingData(){
    if(this.ticket && this.startDate && this.endDate){
      this.setCompany().subscribe((data)=>{
        if(!isNull(data)){
          this.company = data;
          //If loadWorkspace flag is in true, then execute below code

          if(this.showFullSpinner)
            this.spinnerService.showSpinner.emit(true);
          else
            this.isFindingData = true;

          if(!this.loadWorkspace){
            this.loadConfigFromLocal();
            return;
          }
          this.loadWorkspaceFunction().subscribe((data: any) => {
            if (data && data.config && this.loadWorkspace) {
              this.option = JSON.parse(data.config);
              //Adding tooltip config before render graph
              this.option.tooltip = {
                shape: 'square',
                headerShape: 'callout',
                borderWidth: 0,
                shadow: true,
                positioner: function (width, height, point) {
                  let chart = this.chart,
                    position;

                  if (point.isHeader) {
                    position = {
                      x: Math.max(
                        // Left side limit
                        chart.plotLeft,
                        Math.min(
                          point.plotX + chart.plotLeft - width / 2,
                          // Right side limit
                          chart.chartWidth - width - chart.marginRight
                        )
                      ),
                      y: point.plotY
                    };
                  } else {
                    position = {
                      x: point.series.chart.plotLeft,
                      y: point.series.yAxis.top - chart.plotTop
                    };
                  }
                  return position;
                }
              };

              this.chart = this.Highcharts.stockChart(this.option);
              this.drawResistance();
              this.drawSupport();
              this.removeResistance();
              this.removeSupport();

              if(this.showFullSpinner)
                this.spinnerService.showSpinner.emit(false);
              else
                this.isFindingData = false;

              this.graphService.chartReady.emit();
            }
            else{
              this.loadConfigFromLocal();
            }
          }, (error) => {
          });
        }
      },error => {
        if(this.showFullSpinner)
          this.spinnerService.showSpinner.emit(false);
        else
          this.isFindingData = false;
      });
    }
  }

  public loadConfigFromLocal(){
    //If loadWorkspace flag is in false, then execute below code
    this.setBody();
    this.setOptions();
    this.requestSubscription = this.httpClient.post(this.config.api + '/v1/api/stock/by-range',
      this.body)
      .subscribe((data)=>{
        let items:any = data
        for (let i = 0; i < items.length; i++) {
          this.data.push([Number(items[i]['datetime']), Number(items[i]['open']), Number(items[i]['high']), Number(items[i]['low']), Number(items[i]['close']), Number(items[i]['volume'])]);
        }
        // split the data set into ohlc and volume

        let dataLength: any = items.length,
          i: any = 0;

        this.ohlc = [];
        this.volume = [];
        for (i; i < dataLength; i += 1) {
          this.ohlc.push([
            Number(items[i]['datetime']), // the date
            Number(items[i]['open']), // open
            Number(items[i]['high']), // high
            Number(items[i]['low']), // low
            Number(items[i]['close']) // close
          ]);

          this.volume.push([
            Number(items[i]['datetime']), // the date
            Number(items[i]['volume']) // the volume
          ]);
        };
        this.option.series = [
          {
            type: this.barType,
            id: this.ticket + '-candlestick',
            name: this.ticket + ' Stock Price',
            data: this.ohlc
          }
        ];
        this.chart = this.Highcharts.stockChart('container',this.option);

        if(this.showFullSpinner)
          this.spinnerService.showSpinner.emit(false);
        else
          this.isFindingData = false;

        this.drawResistance();
        this.drawSupport();
        this.removeResistance();
        this.removeSupport();
        this.graphService.chartReady.emit();
      },error => {
        this.toast.error(error.error.error, "Error");

        if(this.showFullSpinner)
          this.spinnerService.showSpinner.emit(false);
        else
          this.isFindingData = false;
      });
  }

  public cleanGraph(){
    if(this.chart){
      this.chart.destroy();
      this.ticket = null;
      this.startDate = null;
      this.endDate = null;
      this.company = null;
    }
    if(this.workspaceTimeInterval)
      clearInterval(this.workspaceTimeInterval);
  }

  ngOnDestroy(): void {
    if(this.timingSubscription != null)
      this.timingSubscription.unsubscribe();

    if(this.ticketSubscription != null)
      this.ticketSubscription.unsubscribe();

    if(this.startDateSubscription != null)
      this.startDateSubscription.unsubscribe();

    if(this.endDateSubscription != null)
      this.endDateSubscription.unsubscribe();

    if(this.cleanGraphSubscription != null)
      this.cleanGraphSubscription.unsubscribe();

    if(this.ticketSubscription != null)
      this.ticketSubscription.unsubscribe();

    if(this.requestSubscription != null)
      this.requestSubscription.unsubscribe();

    if(this.removeResistanceSubscription != null)
      this.removeResistanceSubscription.unsubscribe();

    if(this.removeSupportSubscription != null)
      this.removeSupportSubscription.unsubscribe();

    if(this.reDrawSubscription != null)
      this.reDrawSubscription.unsubscribe();

    if(this.workspaceTimeInterval != null)
      clearInterval(this.workspaceTimeInterval);

    //saving workspace before destroy component
    this.saveWorkspaceFunction();
  }

  public loadWorkspaceFunction(){
    if(this.loadWorkspace){
      return this.httpClient.post(this.config.api + "/v1/api/stock/get-workspace", {
        "token": this.authService.getUser().token,
        "symbol": this.ticket,
        "timing": this.timing
      });
    }
  }

  public saveWorkspaceFunction(){
    if(this.chart && this.option && this.saveWorkspace){

      var navigation = this.chart.navigationBindings,
        navChart = navigation.chart,
        indicators = [],
        flags = [];

      let options = JSON.parse(JSON.stringify(this.option));

      if(navChart.series){
        options.series = [];
        navChart.series.forEach(function(series) {
          options.series.push(series.userOptions);
        });
      }

      if(navChart.xAxis){
        options.xAxis = [];
        navChart.xAxis.forEach(function(xAxis) {
          options.xAxis.push(xAxis.options);
        });
      }

      if(navChart.yAxis){
        options.yAxis = [];
        navChart.yAxis.forEach(function(yAxis) {
          options.yAxis.push(yAxis.options);
        });
      }

      if(navChart.annotations){
        options.annotations = [];
        navChart.annotations.forEach(function(annotation, index) {
          options.annotations.push(annotation.userOptions);
        });
      }

      if(this.chart.navigationBindings.chart.annotations &&
        this.cloneChart.navigationBindings &&
        this.cloneChart.navigationBindings.chart &&
        this.cloneChart.navigationBindings.chart.annotations &&
        this.chart.navigationBindings.chart.annotations.length == this.cloneChart.navigationBindings.chart.annotations.length){
        return;
      }

      this.cloneChart = _.cloneDeep(this.chart);

      //Save workspace only if there are differences
      this.httpClient.post(this.config.api + "/v1/api/stock/save-workspace",{
        "token": this.authService.getUser().token,
        "symbol": this.ticket,
        "timing": this.timing,
        "config": JSON.stringify(options)
      }).subscribe((data)=>{
        // this.option = options;
      },(error)=>{

      });
    }
  }
}
