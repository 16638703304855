import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'static-report-filter',
  templateUrl: './static-report-filter.component.html',
  styleUrls: ['./static-report-filter.component.css']
})
export class StaticReportFilterComponent implements OnInit {

  @Input()  currentReportFilter: any = [];
  @Output() currentReportFilterChange = new EventEmitter<any>();

  rangeDate: Date[] = [(new Date())];

  constructor() { }

  ngOnInit() {

  }

  getJsonFromString(data){
    let dataJson =  JSON.parse(data);
    return dataJson;
  }

  emitFilter(){
    this.currentReportFilterChange.emit(this.currentReportFilter);
  }

}
