import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../services/config.service';
import {SpinnerService} from '../../services/spinner.service';
import {ToastrService} from 'ngx-toastr';
import {ModalService} from '../common/modal/modal.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  keyword:string = "";
  searching: boolean = false;
  creatingUser:boolean = false;
  users = [];

  constructor(private httpClient: HttpClient,
              private configService: ConfigService,
              private spinnerService: SpinnerService,
              private toastrService: ToastrService,
              private modalService: ModalService) { }

  ngOnInit() {
    this.httpClient.get(this.configService.getLocalConfig()['api'] + "/v1/api/user").subscribe((data:[])=>{
      this.users = data;
      for (let item of this.users){
        item.editFirstName = false;
        item.editLastName = false;
        item.editEmail = false;
        item.editPhoneNumber = false;
        item.editStatus = false;
      }
    },error => {
    });
  }

  public saveUser(user, activity, position){
    this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/user/update",{
      "id": user.id,
      "firstName": user.firstName,
      "lastName": user.lastName,
      "phoneNumber": user.phoneNumber,
      "email": user.email,
      "status": user.status
    }).subscribe(value=>{
      this.toastrService.success("User updated successfully", "Info");
      this.users[position][activity] = false;
    }, error => {
      this.toastrService.error("Error updating user", "Error");
      this.users[position][activity] = true;
    })
  }

  public searchUser(event) {
    this.keyword += event.key;
    this.searching = true;
    this.httpClient.get(this.configService.getLocalConfig()['api'] + "/v1/api/user/" + this.keyword).subscribe(data=>{
      this.searching = false;
    },error => {

      this.searching = true;
    });
  }

}
