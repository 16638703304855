import {Component, OnDestroy, OnInit, Output} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {isUndefined} from 'util';
import {User} from './user.module';
import {SpinnerService} from '../../services/spinner.service';
import {AuthService} from './auth.service';
import {ConfigService} from '../../services/config.service';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../user/user.service';
import {interval, Subscriber, Subscription} from 'rxjs';
import {isNull} from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit, OnDestroy {

  phoneConfirmation: boolean = false;
  formTitle:string = "Login";
  reCaptchaCode: string = '';
  user: User;
  messageType:string;
  showFormLogin: boolean = true;
  counterInSeconds:number = 0;
  reversalCounterSubscription:Subscription;
  preferenceReceivedSubscription: Subscription;

  constructor(private authService: AuthService,
              private configService: ConfigService,
              private spinnerService: SpinnerService,
              private router: Router,
              private route: ActivatedRoute,
              private toast: ToastrService,
              private userService: UserService) {
  }

  public ngOnInit() {

  }

  public ngOnDestroy() {
    if (this.reversalCounterSubscription!=null){
      this.reversalCounterSubscription.unsubscribe();
    }
  }

  public register(formRegister: NgForm){
    if(!formRegister.valid){
      return;
    }
    this.user = new User();
    this.user.firstName = formRegister.value.firstName;
    this.user.lastName = formRegister.value.lastName;
    this.user.password = formRegister.value.password;
    this.user.phoneNumber = formRegister.value.phoneNumber;
    this.user.email = formRegister.value.email;

    this.spinnerService.showSpinner.emit(true);
    this.authService.createUser(this.user, this.reCaptchaCode).subscribe(
      resData=>{
        this.spinnerService.showSpinner.emit(false);
        this.formTitle = "Confirmation code";
        this.phoneConfirmation = true;
        this.counterInSeconds = 60;
        this.reversalCounter();
        if(!isUndefined(resData.messageType)){
          this.messageType = resData.messageType;
        }
    },
    error=>{
        this.spinnerService.showSpinner.emit(false);
    });
  }

  public login(formLogin: NgForm){
    if(!formLogin.valid)
      return;

    this.spinnerService.showSpinner.emit(true);
    this.authService.login(formLogin.value.email, formLogin.value.password).subscribe(data=>{
      this.spinnerService.showSpinner.emit(false);
      if(!isUndefined(data.user)){
        formLogin.reset();
        this.formTitle = "Confirmation code";
        this.phoneConfirmation = true;
        this.counterInSeconds = 60;
        this.reversalCounter();
        if(!isUndefined(data.messageType)){
          this.messageType = data.messageType;
          this.setUserOnService(data);
        }
      }
    },error=>{
      this.spinnerService.showSpinner.emit(false);
    });

  }

  public resolved(captchaResponse: string) {
    this.reCaptchaCode = captchaResponse;
  }

  public sendVerificationCode(form: NgForm){
    form.reset();
    this.spinnerService.showSpinner.emit(true);
    this.authService.sendVerificationCode(this.user.email).subscribe(data=>{
      this.spinnerService.showSpinner.emit(false);
    },error=>{
      this.spinnerService.showSpinner.emit(false);
    });
  }

  public checkVerificationCode(formConfirmation: NgForm){
    this.spinnerService.showSpinner.emit(true);
    this.authService.checkVerificationCode(this.user.email, formConfirmation.value.code).subscribe(data=>{
      if(!isUndefined(data.user)){
        //User was registered successfully
        this.authService.isAuthToggle.emit(true);
        this.setUserOnService(data);
        this.authService.isAuth = true;
        this.authService.saveUserOnStorage();
        this.userService.getRemoteUserPreference(this.user.id);
        this.showFormLogin = false;
        this.preferenceReceivedSubscription = this.userService.preferenceReceived.subscribe((value:boolean)=>{
          if(value){
            this.spinnerService.showSpinner.emit(false);
            this.router.navigate(['dashboard']);
            this.preferenceReceivedSubscription.unsubscribe();
          }
        });
      }
      else{
        //Process invalid conformation code, show error message to the user
        this.spinnerService.showSpinner.emit(false);
        this.toast.error(formConfirmation.value.code + " is invalid", "Error");
        formConfirmation.reset();
      }
    },error=>{
      this.spinnerService.showSpinner.emit(false);
    });
  }

  public setUserOnService(data){
    this.user = new User();
    this.user.id = data.user.id;
    this.user.firstName = data.user.firstName;
    this.user.lastName = data.user.lastName;
    this.user.lastName = data.user.lastName;
    this.user.email = data.user.email;
    this.user.phoneNumber = data.user.phoneNumber;
    this.user.token = data.user.token;
    this.user.tokenExpire = data.user.tokenExpire;
    this.user.permissions = data.user.permissions;
    this.authService.setUser(this.user);
  }

  public reversalCounter(){
    this.reversalCounterSubscription = interval(1000).subscribe(()=>{
      if(this.counterInSeconds==0){
        this.toast.info("Confirmation code has been expired", "Notification");
        this.formTitle = "Login";
        this.phoneConfirmation = false;
        this.reversalCounterSubscription.unsubscribe();
      }
      else{
        this.counterInSeconds--;
      }
    });
  }

}
