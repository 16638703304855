import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ModalService} from '../../../common/modal/modal.service';
import {Member} from '../../member/member.module';
import {ConfigService} from '../../../../services/config.service';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {SpinnerService} from '../../../../services/spinner.service';
import {ParticipationModalService} from './participation-modal.service';
import {MyDateService} from '../../../../services/myDate.service';

@Component({
  selector: 'participation-modal',
  templateUrl: './participation-modal.component.html',
  styleUrls: ['./participation-modal.component.css']
})
export class ParticipationModalComponent implements OnInit {

  @Input() showModal: boolean = false;
  @Output() showModalChange = new EventEmitter<boolean>();
  @Input() account:{
    id: string,
    number: string
  } = null;

  selectedMember: Member = null;
  selectedBankAccount:{
    id:string,
    alias:string,
    number: number,
    routing: number
  } = null;
  bankingStatus:string = 'HIDDEN';

  amount: number = null;
  date:any = new Date();
  isActive:boolean = true;
  isContribution: boolean = true;
  description:string = "";
  bankAlias: string = null;
  bankAccountNumber: number = null;
  bankRouting: number = null;

  //Subscriptions
  showModalSubscription: Subscription;

  constructor(private modalService: ModalService,
              private configService: ConfigService,
              private httpClient: HttpClient,
              private toast: ToastrService,
              private spinnerService: SpinnerService,
              private contributionModalService: ParticipationModalService,
              private myDateService: MyDateService) { }

  ngOnInit() {
    this.showModalSubscription = this.modalService.showModal.subscribe(value=>{
      this.showModal = value;
      this.showModalChange.emit(this.showModal)
    });

  }

  ngOnDestroy() {
    this.showModalSubscription.unsubscribe();
  }

  newContribution(form: NgForm){
    if(!form.invalid){
      if(this.bankingStatus == 'NEW'){
        this.saveNewBakingAccount(form);
      }
      else if(this.bankingStatus == 'EXIST'){

      }
      else if(this.bankingStatus == 'HIDDEN'){
        this.saveNewParticipation(form);
      }
    }
  }

  public saveNewBakingAccount(form: NgForm){
    //Saving new banking account
      if(this.selectedMember == null){
        this.toast.error("Please, select one member", "Error");
        return;
      }
      this.spinnerService.showSpinner.emit(true);
      this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/member/createBankAccount",{
        bankAccountNumber:this.bankAccountNumber,
        bankRouting: this.bankRouting,
        bankAlias: this.bankAlias,
        memberId: this.selectedMember.id
      }).subscribe((data:any)=>{
        if(data !== 'undefined'){
          this.saveNewParticipation(form, data);
        }
      }, error => {

      });
      this.spinnerService.showSpinner.emit(false);
  }

  public saveNewParticipation(form: NgForm, data:any=null){
    if(data != null){
      this.selectedBankAccount = {
        id: data.id,
        alias: data.alias,
        number: data.number,
        routing: data.routing
      };
    }

    let url = "";
    if(this.isContribution)
      url = "/v1/api/member/create-contribution";
    else
      url = "/v1/api/member/create-retribution";

    this.httpClient.post(this.configService.getLocalConfig().api + url,{
      foundId: ((this.selectedBankAccount != null)?this.selectedBankAccount.id:null),
      memberId: this.selectedMember.id,
      accountId: this.account.id,
      amount: this.amount,
      isActive: this.isActive,
      date: this.myDateService.getFormatedDate(this.date),
      description: this.description
    }).subscribe((data:any)=>{
      if(data !== 'undefined' && data['message'] !== 'undefined'){
        this.toast.success(data['message'], 'Message');
        this.contributionModalService.needToReload.emit();
        this.closeModal(form);
      }
    },error => {

    });
  }

  closeModal(form: NgForm){
    this.modalService.showModal.emit(false);
    this.selectedMember = null;
    this.selectedBankAccount = null;
    this.bankingStatus = 'HIDDEN';
    this.amount = null;
    this.isActive = false;
    this.isContribution = true;
    this.bankAlias = null;
    this.bankAccountNumber = null;
    this.bankRouting = null;
    form.onReset();
  }

}
