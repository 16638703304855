import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dynamic-report',
  templateUrl: './dynamic-report.component.html',
  styleUrls: ['./dynamic-report.component.css']
})
export class DynamicReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
