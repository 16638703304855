import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {SearchInstrumentWithBubbleTagService} from '../../common/search-instrument-with-bubble-tag/search-instrument-with-bubble-tag.service';
import {Subscription} from 'rxjs';
import {InstrumentComparatorGraphService} from './instrument-comparator-graph/instrument-comparator-graph.service';

@Component({
  selector: 'app-instrument-comparator',
  templateUrl: './instrument-comparator.component.html',
  styleUrls: ['./instrument-comparator.component.css']
})
export class InstrumentComparatorComponent implements OnInit, OnDestroy {

  instrumentBase:{id:string, code:string, name:string} = {id: "defaultAccount", code: "defaultAccount", name: "defaultAccount"};
  instrumentSecondaries:{id:string, code:string, name:string}[] = [];
  rangeDate: Date[] = [];
  fromDate:any = null;
  toDate:any = null;
  showWallet: boolean = true;
  isFullScreen:boolean = false;
  @ViewChild('instrumentComparator', {static:false}) walletHistoric: ElementRef;
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event) {
    this.isFullScreen = !this.isFullScreen;
  }

  //Subscription
  listHasChangedSubscription:Subscription;

  constructor(private searchInstrumentWithBubbleTagService: SearchInstrumentWithBubbleTagService,
              private reloadGraphService: InstrumentComparatorGraphService) { }

  ngOnInit() {
    this.rangeDate = [(new Date())];
    this.listHasChangedSubscription = this.searchInstrumentWithBubbleTagService.listHasChanged.subscribe(value=>{
      this.reloadGraphService.instrumentBase.emit(this.instrumentBase);
      this.reloadGraphService.instrumentSecondaries.emit(this.instrumentSecondaries);
      this.reloadGraphService.reloadGraph.emit();
    });
  }

  ngOnDestroy(): void {
    this.listHasChangedSubscription.unsubscribe();
  }

  public resetGraph(){
    if(this.rangeDate && this.rangeDate.length == 2){
      this.fromDate = this.getFormatedDate(this.rangeDate[0]);
      this.toDate = this.getFormatedDate(this.rangeDate[1]);
      this.reloadGraphService.reloadGraph.emit();
    }
  }

  public getFormatedDate(date:Date) {
    if (date) {
      let stringDate = moment(date).format("YYYY-MM-DD");
      return stringDate;
    } else
      return null;
  }

  public toggleShowMyWallet(){
    if(this.showWallet){
      if(this.instrumentBase!=null){
        this.instrumentSecondaries.unshift(this.instrumentBase);
      }
      this.instrumentBase = {id: "defaultAccount", code: "defaultAccount", name: "defaultAccount"};
    }
    else{
      if(this.instrumentSecondaries.length>0){
        this.instrumentBase = this.instrumentSecondaries[0];
        this.instrumentSecondaries.splice(this.instrumentSecondaries.indexOf(this.instrumentBase),1);
      }
      else{
        this.instrumentBase = null;
      }
    }
    this.reloadGraphService.instrumentBase.emit(this.instrumentBase);
    this.reloadGraphService.instrumentSecondaries.emit(this.instrumentSecondaries);
    this.reloadGraphService.reloadGraph.emit();
  }


  public fullScreen(element: HTMLElement){
    if(!this.isFullScreen){
      if (element.requestFullscreen)
        element.requestFullscreen();
    }
    else {
      if (document.exitFullscreen)
        document.exitFullscreen();
    }
  }

}
