import { Injectable } from '@angular/core';

import * as localConfig from '../../config/config.json';

@Injectable({
    providedIn: 'root'
})

export class ConfigService {

  constructor(){}

  public getLocalConfig(){
    return (localConfig as any).default;
  }
}
