import {NgModule} from "@angular/core";
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from '../app/dashboard/dashboard.component';
import {ChartComponent} from '../app/chart/chart.component';
import {ScreenerComponent} from '../app/screener/screener.component';
import {PageNotFoundComponent} from '../app/page-not-found/page-not-found.component';
import {ReportComponent} from '../app/report/report.component';
import {WatchlistComponent} from '../app/watchlist/watchlist.component';
import {ToolsComponent} from '../app/tools/tools.component';
import {StockCalculatorAdvComponent} from '../app/tools/stock-calculator-adv/stock-calculator-adv.component';
import {AuthComponent} from '../app/auth/auth.component';
import {RegisterComponent} from '../app/register/register.component';
import {UserComponent} from '../app/user/user.component';
import {GroupComponent} from '../app/group/group.component';
import {TaskComponent} from '../app/task/task.component';
import {AlertComponent} from '../app/alert/alert.component';
import {NotificationComponent} from '../app/notification/notification.component';
import {AuthGuard} from '../app/auth/auth.guard';
import {OpportunitySeekerComponent} from '../app/tools/opportunity-seeker/opportunity-seeker.component';
import {WalletComponent} from '../app/wallet/wallet.component';
import {AccountComponent} from '../app/account/account.component';
import {InstrumentComparatorComponent} from '../app/tools/instrument-comparator/instrument-comparator.component';
import {TransactionComponent} from '../app/transaction/transaction.component';
import {OperationComponent} from '../app/operation/operation.component';
import {OrderComponent} from '../app/order/order.component';
import {MemberComponent} from '../app/contribution/member/member.component';
import {AccountMappingComponent} from '../app/contribution/account-mapping/account-mapping.component';
import {OptionManagementComponent} from '../app/option/option-management/option-management.component';
import {StaticReportComponent} from '../app/report/static-report/static-report.component';
import {DynamicReportComponent} from '../app/report/dynamic-report/dynamic-report.component';
import {EmptyComponent} from '../app/empty/empty.component';


const routes: Routes = [
  {path: '.well-known/acme-challenge/:value', component: EmptyComponent },
  {path: 'auth', component: AuthComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'chart/:symbol/:timing', component: ChartComponent, canActivate: [AuthGuard]},
  {path: 'chart', component: ChartComponent, canActivate: [AuthGuard]},
  {path: 'account/activate', component: AccountComponent, canActivate: [AuthGuard]},
  {path: 'wallet', component: WalletComponent, canActivate: [AuthGuard]},
  {path: 'account', component: AccountComponent, canActivate: [AuthGuard]},
  {path: 'user', component: UserComponent, canActivate: [AuthGuard]},
  {path: 'group', component: GroupComponent, canActivate: [AuthGuard]},
  {path: 'task', component: TaskComponent, canActivate: [AuthGuard]},
  {path: 'alert', component: AlertComponent, canActivate: [AuthGuard]},
  {path: 'notification', component: NotificationComponent, canActivate: [AuthGuard]},
  {path: 'watchlist', component: WatchlistComponent, canActivate: [AuthGuard]},
  {path: 'transaction', component: TransactionComponent, canActivate: [AuthGuard]},
  {path: 'operation', component: OperationComponent, canActivate: [AuthGuard]},
  {path: 'order', component: OrderComponent, canActivate: [AuthGuard]},
  {path: 'screener', component: ScreenerComponent, canActivate: [AuthGuard]},
  {path: 'tools', component: ToolsComponent, canActivate: [AuthGuard]},
  {path: 'stock-calculator-advance', component: StockCalculatorAdvComponent, canActivate: [AuthGuard]},
  {path: 'opportunity-seeker', component: OpportunitySeekerComponent, canActivate: [AuthGuard]},
  {path: 'instrument-comparator', component: InstrumentComparatorComponent, canActivate: [AuthGuard]},
  {path: 'static-report', component: StaticReportComponent, canActivate: [AuthGuard]},
  {path: 'dynamic-report', component: DynamicReportComponent, canActivate: [AuthGuard]},
  {path: 'member', component: MemberComponent, canActivate: [AuthGuard]},
  {path: 'account-mapping', component: AccountMappingComponent, canActivate: [AuthGuard]},
  {path: 'option-management', component: OptionManagementComponent, canActivate: [AuthGuard]},
  {path: 'page-not-found', component: PageNotFoundComponent, canActivate: [AuthGuard]},
  {path: '**', redirectTo: 'page-not-found', canActivate: [AuthGuard]}
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class RoutingModule  {

}
