import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InstrumentComparatorGraphService {

  reloadGraph = new EventEmitter<void>();
  instrumentBase = new EventEmitter<{id:string, code:string, name:string}>();
  instrumentSecondaries = new EventEmitter<{id:string, code:string, name:string}[]>();
}
