import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SpinnerService} from '../../../services/spinner.service';
import {CompanySearchService} from '../company-search/company-search.service';
import * as moment from 'moment';
import {ConfigService} from '../../../services/config.service';
import {SearchInstrumentWithBubbleTagService} from './search-instrument-with-bubble-tag.service';

@Component({
  selector: 'search-instrument-with-bubble-tag',
  templateUrl: './search-instrument-with-bubble-tag.component.html',
  styleUrls: ['./search-instrument-with-bubble-tag.component.css']
})
export class SearchInstrumentWithBubbleTagComponent implements OnInit {

  isSearching: boolean = false;
  result: any = [];
  @Input() companyList: any = []; // [{id: string, code: string, name: string}]
  @Output() companyListChange = new EventEmitter<[]>();
  config: any;
  @ViewChild('criteria', {static: true}) criteriaComponent: ElementRef;
  @Input() showSpinner: boolean = true;
  @Output() clickingFilter = new EventEmitter();
  @Output() closingFilter = new EventEmitter();

  constructor(private httpClient: HttpClient,
              private spinnerService: SpinnerService,
              private configService: ConfigService,
              private searchInstrumentWithBubbleTagService: SearchInstrumentWithBubbleTagService) {
    this.config = this.configService.getLocalConfig();
    this.isSearching = false;
  }

  ngOnInit() {
  }

  public searchCompany(value: string){
    if(value == null || value == "" || value.length < 3){
      this.result = [];
      return;
    }
    this.isSearching = true;

    if(this.showSpinner)
      this.spinnerService.showSpinner.emit(true);

    this.httpClient.get(this.config.api + "/v1/api/stock/searchByKeyword/" + value).subscribe((data: any)=>{
      if(data && Array.isArray(data) && data.length > 0){
        this.result = data;
      }
      if(this.showSpinner)
        this.spinnerService.showSpinner.emit(false);

      this.isSearching = false;

    },(error)=>{
      if(this.showSpinner)
        this.spinnerService.showSpinner.emit(false);

      this.isSearching = false;
    });
  }

  public selectCompany(company: {id: string, code: string, name: string}){
    for(let item of this.companyList){
      if(company.id == item.id){
        this.result = [];
        this.criteriaComponent.nativeElement.value = "";
        return;
      }
    }
    this.companyList.push(company);
    this.companyListChange.emit(this.companyList);
    this.searchInstrumentWithBubbleTagService.listHasChanged.emit()
    this.result = [];
    this.criteriaComponent.nativeElement.value = "";
  }

  public removeCompanyFromList(company: {id: string, code: string, name: string}){
    this.companyList.splice(this.companyList.indexOf(company),1);
    this.companyListChange.emit(this.companyList);
    this.searchInstrumentWithBubbleTagService.listHasChanged.emit()
  }

}
