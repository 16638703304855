import { Pipe } from '@angular/core';
import * as moment from 'moment';
import {isNull} from 'util';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe {
  transform(date, format) {
    if(date == undefined || isNull(date))
      return null;
    else if(format == undefined)
      return moment(date).format('LLLL');
    else
      return moment(date).format(format);
  }
}
