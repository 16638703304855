import {
  AfterContentChecked,
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.css']
})
export class MultiselectComponent implements OnInit, OnChanges {

  searchText: string = "";
  @Input() listItems = [];
  @Output() listItemsOutput = new EventEmitter<any>();
  isExpanded: boolean = false;
  @Input() placeholder: string = "Select...";
  areAllItemSelected:boolean=false;
  areSomeItemSelected:boolean=false;
  @Input() executeChecking:boolean=false;
  @Output() executeCheckingChange = new EventEmitter<boolean>();

  @HostListener('document:click', ['$event']) toggleOpen(event: Event) {
    if (this._eref.nativeElement.contains(event.target)){
      if((event.target as HTMLElement).id == 'multiselectArrow'){
        this.isExpanded = !this.isExpanded;
        this.searchText = "";
      }
    }
    else {
      this.isExpanded = false;
    }
  }

  constructor(private _eref: ElementRef) { }

  ngOnInit() {
    this.checkAreSomeItemSelected();
    this.checkAreAllItemSelected();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.executeChecking && changes.executeChecking.previousValue == false && changes.executeChecking.currentValue == true){
      this.checkAreSomeItemSelected();
      this.checkAreAllItemSelected();
      this.executeCheckingChange.emit(false);
      this.executeChecking = false;
    }
  }

  public checkAreAllItemSelected(){
    if(this.listItems.length > 0){
      for (let item of this.listItems){
        if(!item.isSelected){
          this.areAllItemSelected = false;
          return;
        }
      }
      this.areAllItemSelected = true;
      return;
    }
  }

  public checkAreSomeItemSelected(){
    for (let item of this.listItems){
      if(item.isSelected){
        this.areSomeItemSelected = true;
        return;
      }
    }
    this.areSomeItemSelected = false;
  }

  public updateAllItemToSelected(){
    for (let item of this.listItems){
      item.isSelected = true;
    }
    this.areAllItemSelected = true;
    this.areSomeItemSelected = true;
  }

  public updateAllItemToUnselected(){
    for (let item of this.listItems){
      item.isSelected = false;
    }
    this.areAllItemSelected = false;
    this.areSomeItemSelected = false;
  }

  public emitListItems(){
    this.checkAreAllItemSelected();
    this.listItemsOutput.emit(this.listItems);
  }

}
