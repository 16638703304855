import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';
import {ToastrService} from 'ngx-toastr';
import {SpinnerService} from '../../../services/spinner.service';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import {GraphModalService} from '../../common/graph-modal/graph-modal.service';
import { saveAs } from 'file-saver';
import {UserService} from '../../user/user.service';
import {ModalService} from '../../common/modal/modal.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'static-report',
  templateUrl: './static-report.component.html',
  styleUrls: ['./static-report.component.css']
})
export class StaticReportComponent implements OnInit, OnDestroy {

  //Icons
  faFilter = faFilter;

  reportList: any = [];
  currentReport = null;
  currentReportFilter: any = [];
  currentReportColumn: any = [];
  currentReportData: any = [];
  orderBy       = null;
  direction   = "ASC";
  limit:number  = 50;
  offset:number = 0;
  total:number  = 0;

  emailAddressList = [];
  showEmailAddressModal = false;
  ownEmailAddress:string;
  emailComment:string = "";

  //Subscriptions
  modalStatusSubscription: Subscription;

  constructor(private httpClient: HttpClient,
              private configService: ConfigService,
              private spinnerService: SpinnerService,
              private toast: ToastrService,
              private modal: ModalService,
              private graphModalService: GraphModalService,
              private userService: UserService) { }

  ngOnInit() {
    this.getReportList();
    let userLocal = this.userService.getUserFromLocalStorage();
    if(userLocal != null && userLocal._email != undefined)
      this.ownEmailAddress = userLocal._email;
    this.modalStatusSubscription = this.modal.showModal.subscribe((status:boolean)=>{
      this.showEmailAddressModal = status;
    });
  }

  ngOnDestroy() {
    this.modalStatusSubscription.unsubscribe();
  }

  getReportList(){
    this.spinnerService.showSpinner.emit(true);
    this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/static-report/get-static-report").subscribe((data:any)=>{
      this.reportList = data;
      this.spinnerService.showSpinner.emit(false);
    },error => {
      this.spinnerService.showSpinner.emit(false);
    });
  }

  goToReport(item: any){
    this.spinnerService.showSpinner.emit(true);
    this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/static-report/get-static-report-details-by-id/" + item.id).subscribe((data:any)=>{
      this.currentReport = item;
      if(data['filter']){
        this.currentReportFilter = data['filter'];
      }
      if(data['column']){
        this.currentReportColumn = data['column'];
      }

      if(data['orderBy']){
        this.direction = data['orderBy']['direction'];
        this.orderBy = data['orderBy']['nomenclator'];
      }

      this.spinnerService.showSpinner.emit(false);
    },error => {
      this.spinnerService.showSpinner.emit(false);
    });
  }

  getReportData(){
    let isValidFilter = true;
    for (let item of this.currentReportFilter){
      if(!item.selectedItem){
        this.toast.error("Please, select filters", "Error");
        isValidFilter = false;
        break;
      }
    }
    if(isValidFilter){
      this.spinnerService.showSpinner.emit(true);
      this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/static-report/get-static-report-data",{
        "filter":this.currentReportFilter,
        "report":this.currentReport,
        "limit": this.limit,
        "offset": this.offset,
        "orderBy":this.orderBy,
        "direction":this.direction
      }).subscribe((data:any)=>{
        this.currentReportData = data['data'];
        this.total = data['total'];
        this.spinnerService.showSpinner.emit(false);
      },error => {
        this.spinnerService.showSpinner.emit(false);
      });
    }
  }

  public showGraph(code, timing='MONTH'){
    this.graphModalService.openModal('Graph', 'fa-signal', code, timing);
  }

  backToReportListView(){
    this.total = 0;
    this.currentReport = null;
    this.currentReportFilter = [];
    this.currentReportColumn = [];
    this.currentReportData = [];
  }

  goToPrevious() {
    this.offset = this.offset - this.limit;
    this.getReportData();
  }

  goToNext(){
    this.offset = this.offset+this.limit;
    this.getReportData();
  }

  downloadPDFForDownload(){
    let isValidFilter = true;
    for (let item of this.currentReportFilter){
      if(!item.selectedItem){
        this.toast.error("Please, select filters", "Error");
        isValidFilter = false;
        break;
      }
    }
    if(isValidFilter){
      this.spinnerService.showSpinner.emit(true);
      this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/static-report/generate-pdf",{
        "filter":this.currentReportFilter,
        "report":this.currentReport,
        "column": this.currentReportColumn,
        "orderBy":this.orderBy,
        "direction":this.direction
      }, { responseType: 'blob' }).subscribe((data:any)=>{
        var blob = new Blob([data], { type: "application/pdf;charset=utf-8" });
        saveAs(blob, this.currentReport.name);
        this.spinnerService.showSpinner.emit(false);
      },error => {
        this.spinnerService.showSpinner.emit(false);
      });
    }
  }

  printReport(){
    let isValidFilter = true;
    for (let item of this.currentReportFilter){
      if(!item.selectedItem){
        this.toast.error("Please, select filters", "Error");
        isValidFilter = false;
        break;
      }
    }
    if(isValidFilter){
      this.spinnerService.showSpinner.emit(true);
      this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/static-report/generate-pdf",{
        "filter":this.currentReportFilter,
        "report":this.currentReport,
        "column": this.currentReportColumn,
        "orderBy":this.orderBy,
        "direction":this.direction
      }, { responseType: 'blob' }).subscribe((data:any)=>{
        var blob = new Blob([data], { type: "application/pdf;charset=utf-8" });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
        this.spinnerService.showSpinner.emit(false);
      },error => {
        this.spinnerService.showSpinner.emit(false);
      });
    }
  }

  setOrderBy(item){
    if(!item.canBeSort)
      return;
    if(this.orderBy == null){
      this.direction = "ASC";
      this.orderBy = item;
    }
    else if(this.orderBy.nomenclatorConfigId == item.nomenclatorConfigId){
      this.direction = (this.direction=='ASC'?'DESC':'ASC');
    }
    else if(this.orderBy.nomenclatorConfigId != item.nomenclatorConfigId){
      this.direction = "ASC";
      this.orderBy = item;
    }
    this.getReportData();
  }

  showEmailModal(){
    this.showEmailAddressModal = true;
  }

  sendReportByEmail(){
    let isValidFilter = true;
    for (let item of this.currentReportFilter){
      if(!item.selectedItem){
        this.toast.error("Please, select filters", "Error");
        isValidFilter = false;
        break;
      }
    }
    if(isValidFilter){
      this.showEmailAddressModal = false;
      this.spinnerService.showSpinner.emit(true);
      this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/static-report/send-pdf-report-by-email",{
        "filter":this.currentReportFilter,
        "report":this.currentReport,
        "column": this.currentReportColumn,
        "orderBy":this.orderBy,
        "direction":this.direction,
        "emailList":this.emailAddressList,
        "comment":this.emailComment
      }, { responseType: 'blob' }).subscribe((data:any)=>{
        this.emailComment = "";
        this.spinnerService.showSpinner.emit(false);
      },error => {
        this.spinnerService.showSpinner.emit(false);
      });
    }
  }

}
