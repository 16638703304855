import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-bootstrap-message',
  templateUrl: './bootstrap-message.component.html',
  styleUrls: ['./bootstrap-message.component.css']
})
export class BootstrapMessageComponent implements OnInit {

  @Input() showMessage:boolean = false;
  @Input() subject:string;
  @Input() message:string;
  @Input() messageType:string="alert-success";

  constructor() { }

  ngOnInit() {
  }

  hideMessage(){
    this.showMessage = false;
  }

}
