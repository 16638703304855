import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'bubble-tag',
  templateUrl: './bubble-tag.component.html',
  styleUrls: ['./bubble-tag.component.css']
})
export class BubbleTagComponent implements OnInit {

  @Input()  title = '';
  @Input()  color = 'btn-primary'; //btn-default | btn-primary | btn-success | btn-info | btn-warning | btn-danger
  @Input()  size  = '';//btn-lg | btn-sm | btn-xs
  @Input()  showCloseButton = true;
  @Output() clickingFilter = new EventEmitter();
  @Output() closingFilter = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  clickingFilterFunction(){
    this.clickingFilter.emit();
  }

  closingFilterFunction(){
    this.closingFilter.emit();
  }

}
