import {EventEmitter, Injectable} from '@angular/core';
import {ModalConfirmationService} from '../common/modal-confirmation/modal-confirmation.service';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {ConfigService} from '../../services/config.service';
import {SpinnerService} from '../../services/spinner.service';

@Injectable({
  providedIn:'root'
})
export class  OrderService  {

  orderCancelCompleted = new EventEmitter<void>();
  orderPutCompleted = new EventEmitter<void>();

  constructor(private confirmationService: ModalConfirmationService,
              private httpClient: HttpClient,
              private toastrService: ToastrService,
              private configService: ConfigService,
              private spinnerService: SpinnerService) {}

  public putLongOrder(ticket, quantity, directionality, accountNumber, price, orderType){
    let question = 'Do you want to put a <strong>long order</strong> with the following details? ' +
      '<div style="margin-bottom: 2px"><br><br><strong>Code:</strong> ' + ticket + "</div>" +
      '<div style="margin-bottom: 2px"><br><strong>Qty:</strong> ' + quantity + "</div>" +
      '<div style="margin-bottom: 2px"><br><strong>Price:</strong> ' + price + "</div>" +
      '<div style="margin-bottom: 2px"><br><strong>Inversion:</strong> ' + (price*quantity).toFixed(2) + "</div>";
    let confirmation = this.confirmationService.onOpen(question)
      .subscribe((value: boolean)=>{
        if(value){
          this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/order/put-order",{
            'ticket':ticket,
            'quantity': quantity,
            'directionality': directionality,
            'accountNumber': accountNumber,
            'price': price,
            'orderType': orderType
          }).subscribe((response:any)=>{
            this.toastrService.success(response.message, "Success");
            this.orderPutCompleted.emit();
            confirmation.unsubscribe();
          }, error => {
            confirmation.unsubscribe();
          });
        }
      }, error=>{
        confirmation.unsubscribe();
      });
  }

  public putShortOrder(ticket, quantity, directionality, accountNumber, price, orderType){
    let question = 'Do you want to put a <strong>short order</strong> with the following details? ' +
      '<div style="margin-bottom: 2px"><br><br><strong>Code:</strong> ' + ticket + "</div>" +
      '<div style="margin-bottom: 2px"><br><strong>Qty:</strong> ' + quantity + "</div>" +
      '<div style="margin-bottom: 2px"><br><strong>Price:</strong> ' + price + "</div>" +
      '<div style="margin-bottom: 2px"><br><strong>Inversion:</strong> ' + (price*quantity).toFixed(2) + "</div>";
    let confirmation = this.confirmationService.onOpen(question)
      .subscribe((value: boolean)=>{
        if(value){
          this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/order/put-order",{
            'ticket':ticket,
            'quantity': quantity,
            'directionality': directionality,
            'accountNumber': accountNumber,
            'price': price,
            'orderType': orderType
          }).subscribe((response:any)=>{
            this.toastrService.success(response.message, "Success");
            this.orderPutCompleted.emit();
            confirmation.unsubscribe();
          }, error => {
            confirmation.unsubscribe();
          });
        }
      }, error=>{
        confirmation.unsubscribe();
      });
  }

  public putLongOrderWithTrigger(ticket, quantity, directionality, accountNumber, price, orderType, childQuantity, childDirectionality, childPrice, cancelPrice, childOrderType ){
    let question = 'Do you want to put a <strong>long order</strong> with the following details? ' +
      '<div style="margin-bottom: 2px"><br><br><strong>Code:</strong> ' + ticket + "</div>" +
      '<div style="margin-bottom: 2px"><br><strong>Qty:</strong> ' + quantity + "</div>" +
      '<div style="margin-bottom: 2px"><br><strong>Price:</strong> ' + price + "</div>" +
      '<div style="margin-bottom: 2px"><br><strong>Stop Loss:</strong> ' + childPrice + "</div>" +
      '<div style="margin-bottom: 2px"><br><strong>Inversion:</strong> ' + (price*quantity).toFixed(2) + "</div>";
    let confirmation = this.confirmationService.onOpen(question)
      .subscribe((value: boolean)=>{
        if(value){
          let payload = {
            'ticket':ticket,
            'quantity': quantity,
            'directionality': directionality,
            'accountNumber': accountNumber,
            'price': price,
            'orderType': orderType,
            'childQuantity': childQuantity,
            'childDirectionality': childDirectionality,
            'childPrice': childPrice,
            'childOrderType': childOrderType,
            'cancelPrice': cancelPrice
          };
          this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/order/put-order-with-trigger",payload).subscribe((response:any)=>{
            this.toastrService.success(response.message, "Success");
            this.orderPutCompleted.emit();
            this.spinnerService.showSpinner.emit(false);
            confirmation.unsubscribe();
          }, error => {
            this.spinnerService.showSpinner.emit(false);
            confirmation.unsubscribe();
          });
        }
      }, error=>{
        confirmation.unsubscribe();
      });
  }

  public putShortOrderWithTrigger(ticket, quantity, directionality, accountNumber, price, orderType, childQuantity, childDirectionality, childPrice, cancelPrice, childOrderType ){
    let question = 'Do you want to put a <strong>short order</strong> with the following details? ' +
      '<div style="margin-bottom: 2px"><br><br><strong>Code:</strong> ' + ticket + "</div>" +
      '<div style="margin-bottom: 2px"><br><strong>Qty:</strong> ' + quantity + "</div>" +
      '<div style="margin-bottom: 2px"><br><strong>Price:</strong> ' + price + "</div>" +
      '<div style="margin-bottom: 2px"><br><strong>Stop Loss:</strong> ' + childPrice + "</div>" +
      '<div style="margin-bottom: 2px"><br><strong>Inversion:</strong> ' + (price*quantity).toFixed(2) + "</div>";
    let confirmation = this.confirmationService.onOpen(question)
      .subscribe((value: boolean)=>{
        if(value){
          let payload = {
            'ticket':ticket,
            'quantity': quantity,
            'directionality': directionality,
            'accountNumber': accountNumber,
            'price': price,
            'orderType': orderType,
            'childQuantity': childQuantity,
            'childDirectionality': childDirectionality,
            'childPrice': childPrice,
            'childOrderType': childOrderType,
            'cancelPrice': cancelPrice
          };
          this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/order/put-order-with-trigger",payload).subscribe((response:any)=>{
            this.toastrService.success(response.message, "Success");
            this.orderPutCompleted.emit();
            this.spinnerService.showSpinner.emit(false);
            confirmation.unsubscribe();
          }, error => {
            this.spinnerService.showSpinner.emit(false);
            confirmation.unsubscribe();
          });
        }
      }, error=>{
        confirmation.unsubscribe();
      });
  }

  public cancelOrder(orderNumber, orderId){
    let confirmation = this.confirmationService.onOpen("Do you want to remover order with " + orderId +  " ?").subscribe((value: boolean)=>{
      if(value){
        this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/order/cancel-order",{
          accountNumber: orderNumber,
          orderId: orderId
        }).subscribe((response:any)=>{
          this.toastrService.success(response.message, "Success");
          this.orderCancelCompleted.emit();
          confirmation.unsubscribe();
        }, error => {
          confirmation.unsubscribe();
        });
      }
    });
  }

}
