import {Component, Input, OnInit} from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {HttpClient} from '@angular/common/http';
import {SpinnerService} from '../../../services/spinner.service';
import {ToastrService} from 'ngx-toastr';
import {GraphModalService} from '../../common/graph-modal/graph-modal.service';

@Component({
  selector: 'top-winner',
  templateUrl: './top-winner.component.html',
  styleUrls: ['./top-winner.component.css']
})
export class TopWinnerComponent implements OnInit {

  @Input() limit: any = 10;
  @Input() buttonSizeClass = "btn-xs";
  @Input() timing = "WEEK";
  isExpanded: boolean = false;
  searchCompleted: boolean = false;

  winnerList = [];

  constructor(private configService: ConfigService,
              private httpClient: HttpClient,
              private spinnerService: SpinnerService,
              private toastConfig: ToastrService,
              private graphModalService: GraphModalService) {}

  ngOnInit() {
    this.getTopWinners();
  }

  public getTopWinners(){
    this.winnerList = [];
    this.searchCompleted = false;
    this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/stock/get-top-winners/" + this.limit + "/" + this.timing).subscribe((data:any)=>{
      this.winnerList = data;
      this.searchCompleted = true;
    }, error => {

    });
  }

  public showGraph(item, timing='MONTH'){
    this.graphModalService.openModal('Graph', 'fa-signal', item.code, timing);
  }

}
