import { Pipe } from '@angular/core';

@Pipe({
  name: 'filterSearch'
})
export class FilterPipe {
  transform(items, term, field:string=null, filterByKey:boolean=false) {
    if (!items || !term || term == "")
      return items;
    if(field)
      return items.filter(item => item[field].toLowerCase().includes(term.toLowerCase()));
    if(filterByKey){
      return items.filter(item => (Object.keys(item)[0]).toLowerCase().includes(term.toLowerCase()));
    }
    return items.filter(item => item.value.toLowerCase().includes(term.toLowerCase()));
  }
}
