import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {absRound} from 'ngx-bootstrap/chronos/utils/abs-round';

@Pipe({
  name: 'bigNumber'
})
export class BigNumberPipe{
  transform(value: any): any {
    if(isNaN(value))
      return value
    if(value < 1000 )
      return value
    else if(value >=1000 && value <1000000)
      return (value/1000).toFixed(2) + "K";
    else if(value >= 1000000 && value < 1000000000)
      return (value/1000000).toFixed(2) + "M";
    else if(value >= 1000000000)
      return (value/1000000000).toFixed(2) + "B";
  }
}
