import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Member} from '../member.module';
import {ConfigService} from '../../../../services/config.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'search-member-autocomplete',
  templateUrl: './search-member-autocomplete.component.html',
  styleUrls: ['./search-member-autocomplete.component.css']
})
export class SearchMemberAutocompleteComponent implements OnInit {

  @Input() member: Member = null;
  @Output() memberChange = new EventEmitter<Member>();
  memberSearchField:any = "";
  memberList: Member[]  = [];

  constructor(private configService: ConfigService,
              private httpClient: HttpClient) { }

  ngOnInit() {
    if(this.member == null){
      this.memberSearchField = "";
    }
  }

  public findMemberByKeyword(){
    if(this.memberSearchField.length >= 3){
      this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/member/findMemberByKeyword/" + this.memberSearchField).subscribe((data:any)=>{
        this.memberList = [];
        if(data != null){
          for(let item of data){
            let tmpMember: Member = new Member();
            tmpMember.fromArray(item);
            this.memberList.push(tmpMember);
          }
        }
      },error => {

      });
    }
  }

  public selectMember(member: Member){
    this.member = member;
    this.memberChange.emit(this.member);
    this.memberSearchField = member.lastName + ", " + member.firstName;
    this.memberList = [];
  }

}
