import {Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {AlertNotificationService} from './alert-notification-service';

@Component({
  selector: 'alert-notification',
  templateUrl: './alert-notification.component.html',
  styleUrls: ['./alert-notification.component.css']
})
export class AlertNotificationComponent implements OnInit, OnDestroy {

  @Input() message: string;
  showNotificationMessage: Subscription;
  showErrorMessage: Subscription;
  showWarningMessage: Subscription;
  @ViewChild('alertNotification', {static:false}) alertNotification: ElementRef;

  constructor(private alertNotificationService: AlertNotificationService) {
    this.showNotificationMessage = this.alertNotificationService.showNotificationMessage.subscribe((message)=>{
      this.message = message;
    });

    this.showErrorMessage = this.alertNotificationService.showErrorMessage.subscribe((message)=>{
      this.message = message;
    });

    this.showWarningMessage = this.alertNotificationService.showWarningMessage.subscribe((message)=>{
      this.message = message;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.showNotificationMessage.unsubscribe();
    this.showErrorMessage.unsubscribe();
    this.showWarningMessage.unsubscribe();
  }

}
