import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../services/config.service';
import {SpinnerService} from '../../services/spinner.service';
import {ToastrService} from 'ngx-toastr';
import {ModalConfirmationService} from '../common/modal-confirmation/modal-confirmation.service';
import {SecurityService} from '../../services/security.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {isNull} from "util";
import {AuthService} from '../auth/auth.service';
import {AccountService} from './account.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  accounts:any = [];

  constructor(private httpClient: HttpClient,
              private configService: ConfigService,
              private spinnerService: SpinnerService,
              private toast: ToastrService,
              private modalConfirmationService: ModalConfirmationService,
              public securityService: SecurityService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private accountService: AccountService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if(params.code){
        localStorage.setItem('code', params.code);
      }
    });
    if(this.securityService.checkPermissionByNameKey('VMA')){
      this.findMyAccounts();
    }
  }

  public findMyAccounts(showSpinner = true){
    if(showSpinner)
      this.spinnerService.showSpinner.emit(true);

    this.accountService.getMyAccounts().subscribe((data:any)=>{
      if(!isNull(data) && data.length > 0){
        for(let item of data){
          item.editInitialBalance = false;
          item.isDefault = item.isDefault == "true";
        }
        this.accounts = data;
      }

      if(showSpinner)
        this.spinnerService.showSpinner.emit(false);

    }, error => {

    });
  }

  public updateInitialBalance(id, initialBalance){
    this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/account/update-initial-balance",{
      id: id,
      initialBalance: initialBalance
    }).subscribe((data: any)=>{

    }, error => {

    });
  }

  public createAccount(){
    this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/account/get-amt-tk").subscribe((data: any)=>{
      if(data['token']){
        localStorage.removeItem('code');
        let thisReference = this;
        let myWindow = window.open("https://auth.tdameritrade.com/oauth?client_id=" + data['token'] + "%40AMER.OAUTHAP&response_type=code&redirect_uri=" + data['redirectUrl']);
        let intervar = setInterval(function() {
          if(!isNull(localStorage.getItem('code'))){
            let code = localStorage.getItem('code');
            let token = data['token'];
            localStorage.removeItem('code');
            clearInterval(intervar);
            myWindow.close();
            //Saving new Account
            thisReference.httpClient.post(thisReference.configService.getLocalConfig().api + "/v1/api/account/create-my", {
              code: code,
              token: token,
              isActive: true,
              ownerId: thisReference.authService.getUser().id
            }).subscribe((data:any)=>{

            }, error => {

            });
          }
        }, 500);

      }
    });
  }

  public renewAccount(account){
    this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/account/get-access-by-account/" + account.id).subscribe((data:any)=>{
      if(data[0]['appKey']){
        localStorage.removeItem('code');
        let thisReference = this;
        let myWindow = window.open("https://api.schwabapi.com/v1/oauth/authorize?client_id=" + data[0]['appKey'] + "&redirect_uri=" + data[0]['redirectUrl']);
        let intervar = setInterval(function() {
          if(!isNull(localStorage.getItem('code'))){
            let code = localStorage.getItem('code');
            localStorage.removeItem('code');
            clearInterval(intervar);
            myWindow.close();
            //Saving new Account
            thisReference.httpClient.post(thisReference.configService.getLocalConfig().api + "/v1/api/account/renew-account", {
              accountId: account.id,
              code: code
            }).subscribe((response:any)=>{
              if(thisReference.securityService.checkPermissionByNameKey('VMA')){
                thisReference.findMyAccounts();
              }
            }, error => {

            });
          }
        }, 500);

      }
    }, error => {

    });
  }

  public toggleDefault(account){
    this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/account/toggle-default",{
      accountId: account.id,
      isDefault: account.isDefault
    }).subscribe((data:any)=>{
      if(this.securityService.checkPermissionByNameKey('VMA')){
        this.findMyAccounts(false);
      }
    }, error => {

    });
  }

  public enableDisableAccount(account, position){
    this.modalConfirmationService.onOpen("Do you want to disable this account?").subscribe((value:boolean)=>{
      if(value){
        this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/account/enable-disable-account",{
          accountId:account.id,
          isActive:account.isActive
        }).subscribe((data:any)=>{

        }, error => {
          this.accounts[position].isActive = !this.accounts[position].isActive;
        });
      }
      else{
        this.accounts[position].isActive = !this.accounts[position].isActive;
      }
    });
  }

}
