import {Component, EventEmitter, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "../../../services/config.service";
import {AccountService} from "../../account/account.service";
import * as moment from "moment";
import {SpinnerService} from "../../../services/spinner.service";

@Component({
  selector: 'option-management',
  templateUrl: './option-management.component.html',
  styleUrls: ['./option-management.component.css']
})
export class OptionManagementComponent implements OnInit {

  selectedCompany:{id: string, code: string, name: string} = null;
  account: any = null;
  chainList: [] = [];
  isChainLoading: boolean = false;

  callSelectedDate:any = new Date('2021-05-06');
  putSelectedDate:any = new Date('2021-05-06');

  callAvailableDates = [];

  putAvailableDates = [];

  constructor(private httpClient: HttpClient,
              private configService: ConfigService,
              private accountService: AccountService,
              private spinnerService: SpinnerService) { }

  ngOnInit() {

  }

  initializingCompany(company: {id: string, code: string, name: string}){
    this.selectedCompany = company;
    this.searchOptionChainsByInstrumentCode();
  }

  removeSelectedCompany(){
    this.selectedCompany = null;
  }

  searchOptionChainsByInstrumentCode(){
    this.spinnerService.showSpinner.emit(true);
    this.isChainLoading = true;
    if(this.selectedCompany != null && this.selectedCompany.code != null){
      this.accountService.getMyDefaultAccount().subscribe((account:any)=>{

        if(account !=null && account != null){
          this.account = account;
        }
        let fromDate = (moment().format(this.configService.getLocalConfig().date.formatShort));
        let toDate   = ((moment().add(30, 'days')).format(this.configService.getLocalConfig().date.formatShort))
        this.httpClient.get(
          this.configService.getLocalConfig().api + "/v1/api/option" + "/find-option-chains-by-instrument-code/" +
               this.selectedCompany.code + "/" + this.account.number + "/" + fromDate + "/" + toDate).subscribe((chainList:any)=>{

          if(chainList['callExpDateMap'])
            this.setCallAvailableDates(chainList['callExpDateMap']);

          if(chainList['putExpDateMap'])
            this.setPutAvailableDates(chainList['putExpDateMap']);

          this.isChainLoading = false;
          this.spinnerService.showSpinner.emit(false);

        }, error => {
          this.isChainLoading = false;
          this.spinnerService.showSpinner.emit(false);
        });
      });
    }
  }

  setCallAvailableDates(callList){
    if(callList != null){
      this.callAvailableDates = [];
      for (const key in callList){
        let date = new Date((key.substring(0, key.search(':'))) + " 00:00:00");
        this.callAvailableDates.push(date);
      }
    }
  }

  setPutAvailableDates(putList){
    if(putList != null){
      this.putAvailableDates = [];
      for (const key in putList){
        let date = new Date((key.substring(0, key.search(':'))) + " 00:00:00");
        this.putAvailableDates.push(date);
      }
    }
  }

  loadCallChainsByDate(){

  }

  loadPutChainsByDate(){

  }

}
