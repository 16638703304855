import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SpinnerService} from '../../services/spinner.service';
import {ConfigService} from '../../services/config.service';
import {AccountService} from '../account/account.service';
import * as _ from 'lodash';

@Component({
  selector: 'transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})
export class TransactionComponent implements OnInit {

  showInListMode:boolean = true;
  transactionList:any = [];
  total:number = 0;
  pageNumber:number = 1;
  countPerPage:number = 30;
  @ViewChild('printElement', {static:false}) printElement: ElementRef;

  constructor(private httpClient: HttpClient,
              private spinnerService: SpinnerService,
              private configService: ConfigService,
              private accountService: AccountService) { }

  ngOnInit() {
    this.findTransactions();
  }

  public findTransactions(){
    this.spinnerService.showSpinner.emit(true);
    this.accountService.getMyDefaultAccount().subscribe((account:any)=>{
      this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/transaction/find-all-by-account-owner/" + account.number).subscribe((data)=>{
        this.transactionList = data;
        this.total = this.transactionList.length;
        this.spinnerService.showSpinner.emit(false);
      },error => {
        this.spinnerService.showSpinner.emit(false);
      });
    });
  }

  public printReport(){
    let contentPrint = this.printElement.nativeElement.innerHTML;
    let winToPrint = Object.assign({}, window);
    // let winToPrint = { ...window};
    // let winToPrint = JSON.parse(JSON.stringify(window));
    // let winToPrint = _.cloneDeep(window);
    winToPrint.document.body.innerHTML = contentPrint;
  }

}
