import {Directive, ElementRef, HostBinding, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[collapseToLeft]'
})
export class LeftCollapseLeftmenuDirective{
  private isCollapse = true;

  @HostListener('click')toggleOpen(){
    this.isCollapse = !this.isCollapse;
    this.apply();
  }

  constructor(private el: ElementRef, private render: Renderer2) {}

  apply(){
    let leftMenu =document.getElementById('left-menu');
    this.render.setStyle(leftMenu, "visibility","hidden");
    this.render.setStyle(leftMenu, "width","0px");
  }

}
