import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MyDateService {

  constructor() {}

  public getFormatedDate(date:Date) {
    if (date) {
      let stringDate = moment(date).format("YYYY-MM-DD");
      return stringDate;
    } else
      return null;
  }
}
