import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  preferenceReceived = new EventEmitter<boolean>();

  constructor(private httpClient: HttpClient,
              private configService: ConfigService) {}

  public getRemoteUserPreference(userId){
    this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/user/get-my-user-preference/" + userId).subscribe((data:any)=>{
      if(data){
        localStorage.setItem('userPreference',data);
      }
      this.preferenceReceived.emit(true);
    }, error => {
      this.preferenceReceived.emit(true);
    });
  }

  public getUserFromLocalStorage(){
    let userLoaded:any = localStorage.getItem('user');
    if(userLoaded){
      userLoaded = JSON.parse(userLoaded);
      return userLoaded;
    }
    else
      return null;
  }
}
