import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import HAccessibility from "highcharts/modules/accessibility";
import {AccountService} from '../../account/account.service';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';;
import * as moment from "moment";
import * as Highcharts from "highcharts/highstock";
import HIndicatorsAll from "highcharts/indicators/indicators-all";
import HDragPanes from "highcharts/modules/drag-panes";
import HAnnotationsAdvanced from "highcharts/modules/annotations-advanced";
import HPriceIndicator from "highcharts/modules/price-indicator";
import HStockTools from "highcharts/modules/stock-tools";

HAccessibility(Highcharts);
HIndicatorsAll(Highcharts);
HDragPanes(Highcharts);
HAnnotationsAdvanced(Highcharts);
HPriceIndicator(Highcharts);
HStockTools(Highcharts);

@Component({
  selector: 'app-wallet-historic',
  templateUrl: './wallet-historic.component.html',
  styleUrls: ['./wallet-historic.component.css']
})
export class WalletHistoricComponent implements OnInit, OnDestroy, OnChanges {

  Highcharts: typeof Highcharts = Highcharts;
  myChart:any = null;
  interval:any = null;
  timing:any = "1D";
  rangeDate: Date[] = [];
  @Input() graphHeight:any = '50%';

  constructor(private accountService: AccountService,
              private httpClient: HttpClient,
              private configService: ConfigService) { }


  ngOnInit() {
    this.rangeDate = [(new Date())];
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.myChart = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.myChart && changes.graphHeight.previousValue != changes.graphHeight.currentValue){
      this.myChart.setSize(null, changes.graphHeight.currentValue);
    }
  }


  public loadWalletHistoric(){
    if(this.interval != null)
      clearInterval(this.interval);

    this.accountService.getMyDefaultAccount().subscribe((data:any)=>{
      if(data && data['id']){
        let url = this.configService.getLocalConfig().api +
                  "/v1/api/account/get-wallet-historic-by-account/" +
                  data['id'] +
                  "/" +
                  ((this.rangeDate.length == 2 && this.rangeDate[0])?this.getFormatedDate(this.rangeDate[0]):null) +
                  "/" +
                  ((this.rangeDate.length == 2 && this.rangeDate[1])?this.getFormatedDate(this.rangeDate[1]):null) +
                  "/" +
                  this.timing;
        this.httpClient.get(url).subscribe((response:any)=>{
          let thisReference = this;
          let options:any = {
            chart: {
              zoomType: 'xy',
              height: this.graphHeight,
              events: {
                load: function() {
                  let thisReferenceB = this;
                  thisReference.interval = setInterval(function () {
                    if( typeof thisReferenceB.series !== 'undefined'){
                      if(thisReferenceB.series.length > 0 && thisReferenceB.series[0].data.length > 0){
                        let series = thisReferenceB.series[0];
                        let lastDateTime = series.data[series.data.length -1]['x'];
                        thisReference.httpClient.get(thisReference.configService.getLocalConfig().api +
                          "/v1/api/account/get-wallet-historic-by-account-and-datetime/" +
                          data['id'] + "/" + lastDateTime)
                          .subscribe((apiData:any)=>{
                            if(apiData){
                              for(let value of apiData){
                                series.addPoint([value[0], value[1]], true, false);
                              }
                            }
                          }, error => {

                          });
                      }
                    }
                  }, 60000);
                },
                redraw: function(e) {
                  if(e.target.axes[0].eventArgs && e.target.axes[0].eventArgs.min && e.target.axes[0].eventArgs.max){
                    let firstAmount = 0, secondAmount = 0;
                    for (let item of thisReference.myChart.xAxis[0].series[0].data){
                      if(e.target.axes[0].eventArgs.min.toFixed(0)>=item.x){
                        firstAmount = item.y;
                      }
                      if(e.target.axes[0].eventArgs.max.toFixed(0)>item.x){
                        secondAmount = item.y
                      }
                      if(e.target.axes[0].eventArgs.max.toFixed(0)<item.x){
                        break;
                      }
                    }
                    let profit:any = (secondAmount - firstAmount).toFixed(2);
                    let profitPercent = (100 * profit / firstAmount).toFixed(2);
                    thisReference.myChart.setTitle(null, {text: '<b>Profit: ' + profit + '(' + profitPercent + '%)</b>'});
                  }
                  else{
                    if(thisReference.myChart.xAxis[0].series[0].data.length > 0){
                      let secondAmount = thisReference.myChart.xAxis[0].series[0].data[thisReference.myChart.xAxis[0].series[0].data.length-1].y;
                      let firstAmount = thisReference.myChart.xAxis[0].series[0].data[0].y;
                      let profit:any = (secondAmount - firstAmount).toFixed(2);
                      let profitPercent = (100 * profit / firstAmount).toFixed(2);
                      thisReference.myChart.setTitle(null, {text: '<b>Profit: ' + profit + '(' + profitPercent + '%)</b>'});
                    }
                  }
                },
              }
            },
            title: {
              text: ''
            },
            subtitle: {
              text: ''
            },
            xAxis: {
              type: 'datetime'
            },
            yAxis:[{
              title: {
                text: 'Capital'
              },
              labels: {
                align: 'left'
              },
              height: '100%',
              crosshair: {
                snap: false,
                label: {
                  enabled: true,
                  format: '{value:.2f}'
                }
              },
              resize: {
                enabled: true
              }
            }],
            legend: {
              enabled: false
            },
            scrollbar: {
              enabled: false
            },
            tooltip: {
              shape: 'square',
              headerShape: 'callout',
              borderWidth: 0,
              shadow: true,
              positioner: function (width, height, point) {
                let chart = this.chart,
                  position;

                if (point.isHeader) {
                  position = {
                    x: Math.max(
                      // Left side limit
                      chart.plotLeft,
                      Math.min(
                        point.plotX + chart.plotLeft - width / 2,
                        // Right side limit
                        chart.chartWidth - width - chart.marginRight
                      )
                    ),
                    y: (point.plotY).toFixed(2)
                  };
                } else {
                  position = {
                    x: point.series.chart.plotLeft,
                    y: (point.series.yAxis.top - chart.plotTop).toFixed(2)
                  };
                }
                return position;
              }
            },
            stockTools: {
              gui: {
                enabled: false
              }
            },
            navigator: {
              enabled: false
            },
            rangeSelector: {
              selected: 0,
              inputEnabled: false,
              enabled: false
            },
            plotOptions: {
              area: {
                fillColor: {
                  linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                  },
                  stops: [
                    [0, Highcharts.getOptions().colors[0]],
                    [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                  ]
                },
                marker: {
                  radius: 2
                },
                lineWidth: 1,
                states: {
                  hover: {
                    lineWidth: 1
                  }
                },
                threshold: null
              }
            },
            series: [{
              type: 'area',
              name: 'Wallet Historic',
              data: response
            }]
          };

          this.myChart = this.Highcharts.stockChart('containerWalletHistoric',options);

          if(thisReference.myChart.xAxis[0].series[0].data.length > 0 && this.myChart.title.textStr == ""){
            let secondAmount = this.myChart.xAxis[0].series[0].data[this.myChart.xAxis[0].series[0].data.length-1].y;
            let firstAmount = this.myChart.xAxis[0].series[0].data[0].y;
            let profit:any = (secondAmount - firstAmount).toFixed(2);
            let profitPercent = (100 * profit/firstAmount).toFixed(2);
            this.myChart.setTitle(null, {text: '<b>Profit: ' + profit + '(' + profitPercent + '%)</b>'});
          }

        }, error => {

        });
      }
    },error => {
    });
  }

  public getFormatedDate(date:Date) {
    if (date) {
      let stringDate = moment(date).format("YYYY-MM-DD");
      return stringDate;
    } else
      return null;
  }
}
