import {EventEmitter, Injectable, OnDestroy, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {InjectableRxStompConfig, RxStompService} from '@stomp/ng2-stompjs';
import {ConfigService} from '../../services/config.service';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskService implements OnInit, OnDestroy{

  private webSocket: WebSocket;
  task = new EventEmitter<any>();
  private config;
  private taskList = [];
  private topicSubscription: Subscription;

  constructor(private rxStompService: RxStompService, private configService: ConfigService) {

  }

  ngOnInit(): void {
    this.config = this.configService.getLocalConfig();
    if(this.config.pushNotification.enable != undefined && this.config.pushNotification.enable){
      this.topicSubscription = this.rxStompService.watch(this.config.pushNotification.vhost).subscribe((message: any) => {
        console.log(message);
      });
    }
  }

  ngOnDestroy(): void {
    this.topicSubscription.unsubscribe();
  }

  public receive(){
    let thisReference = this;
    this.webSocket.onmessage = function(e) {
      let data = JSON.parse(e.data);
      //If task exist on taskList array, then update it
      for (let item of thisReference.taskList){
        if(item['name'] == data['name']){
          item['position'] = data['position'];
          item['percent'] = data['percent'];
          item['total'] = data['total'];
          item['status'] = data['status'];
          thisReference.task.emit(thisReference.taskList);
          return;
        }
      }
      //If task was completed, remove it from taskList
      if(data['status'] == 'COMPLETED'){
          thisReference.taskList = _.remove(thisReference.taskList, function(n) {
            return n['name'] = data['name'];
          });
        thisReference.task.emit(thisReference.taskList);
        return;
      }
      //If it's a new task add to taskList
      thisReference.taskList.push(data);
      thisReference.task.emit(thisReference.taskList);
    }
  }
}
