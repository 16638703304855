import {EventEmitter} from '@angular/core';

export class GraphService{

  ticket = new EventEmitter<string>();
  drawResistance = new EventEmitter<number>();
  drawSupport = new EventEmitter<number>();
  startDate = new EventEmitter<string>();
  endDate = new EventEmitter<string>();
  removeResistance = new EventEmitter<boolean>();
  removeSupport = new EventEmitter<boolean>();
  cleanGraph = new EventEmitter<void>();
  resistance = new EventEmitter<number>();
  support = new EventEmitter<number>();
  timing = new EventEmitter<string>();
  investment_type: string;
  reDraw = new EventEmitter<void>();
  chartReady = new EventEmitter<void>();
}
