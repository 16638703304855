import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SpinnerService} from '../../../services/spinner.service';
import {ConfigService} from '../../../services/config.service';
import {ModalService} from '../../common/modal/modal.service';
import {OpportunitySeekerService} from './opportunity-seeker.service';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {GraphService} from '../../graph/graph.service';
import {TableService} from '../../common/table/table.service';
import {AccountService} from '../../account/account.service';
import {ModalConfirmationService} from '../../common/modal-confirmation/modal-confirmation.service';
import {ToastrService} from 'ngx-toastr';
import {OrderService} from '../../order/order.service';
import {OpportunitySeekerFilterService} from './opportunity-seeker-filter/opportunity-seeker-filter-service';


@Component({
  selector: 'app-opportunity-seeker',
  templateUrl: './opportunity-seeker.component.html',
  styleUrls: ['./opportunity-seeker.component.css']
})
export class OpportunitySeekerComponent implements OnInit, OnDestroy {

  selectedButton: number = 1;
  labelButton1 = 1;
  labelButton2 = 2;
  labelButton3 = 3;
  labelButton4 = 4;
  labelButton5 = 5;
  investmentType: string = 'long';
  capital: any = '';
  risk: any = '';
  config: any;
  symbol: any;
  paginatorStart: number = 0;
  paginatorCount: number = 20;
  total: number;
  account: any;
  column: any = [
    {value:'Name', size:'35%'},
    {value:'Type', size:'5%'},
    {value:'Code', size:'4%'},
    {value:'Resistance', size:'5%'},
    {value:'Support', size:'5%'},
    {value:'Buy Price', size: '5%'},
    {value:'Sell Price', size:'5%'},
    {value:'Volume', size:'6%'},
    {value:'Stocks', size: '4%'},
    {value:'Inversion', size:'5%'},
    {value:'Distance', size:'5%'},
    {value:'Action', size: '16%'}
  ];
  data: any = [];
  showingGraphPosition: number;
  modalVisible: boolean = false;
  assesType:any = [];
  showOnlyQEC = false;
  isExpanded = false;
  yearIterator = [];
  yearIPO = "All";
  showFilterModal = false;
  showGraphModal = false;

  //Subscriptions
  showingGraphPositionSubscription: Subscription;
  modalVisibleSubscription: Subscription;
  requestDataSubscription: Subscription;
  chartReadySubscription: Subscription;
  executeFilterSubscription: Subscription;
  closeModal: Subscription;

  drawingInterval:any;

  constructor(private httpClient: HttpClient,
              private spinnerService: SpinnerService,
              private configService: ConfigService,
              private modalService: ModalService,
              private opportunitySeekerService: OpportunitySeekerService,
              private graphService: GraphService,
              private tableService: TableService,
              private toastrService: ToastrService,
              private accountService: AccountService,
              private confirmationService: ModalConfirmationService,
              private orderService: OrderService,
              private opportunitySeekerFilterService: OpportunitySeekerFilterService) {
    this.config = this.configService.getLocalConfig();
  }

  ngOnInit() {
    for(let i = 0; i < 10; i++){
      let year = moment().add(-i, 'year').year();
      this.yearIterator.push(year);
    }
    this.closeModal = this.modalService.showModal.subscribe(value=>{
      if(!value){
        this.showFilterModal = false;
        this.showGraphModal = false;
      }
    });
    this.showingGraphPositionSubscription = this.opportunitySeekerService.showingGraphPosition.subscribe(value=>{
      this.showingGraphPosition = +value;
      this.showGraph();
    });
    this.modalVisibleSubscription = this.modalService.showModal.subscribe(value=>{
        this.modalVisible = value;
    });
    this.requestDataSubscription = this.tableService.requestData.subscribe(value=>{
      this.requestOpportunityDataToApi();
    });
    this.getAssetType();
    this.findRealCapital();

    this.executeFilterSubscription = this.opportunitySeekerFilterService.executeFilter.subscribe(value=>{
      this.requestOpportunityDataToApi();
      this.showFilterModal = false;
    });
  }

  ngOnDestroy(): void {
    this.showingGraphPositionSubscription.unsubscribe();
    this.modalVisibleSubscription.unsubscribe();

    if(this.chartReadySubscription != undefined)
      this.chartReadySubscription.unsubscribe();
    this.data = [];
  }

  public putLongOrder(){
    this.orderService
        .putLongOrderWithTrigger(this.data[this.showingGraphPosition][2].value,
                                 this.data[this.showingGraphPosition][8].value,
                     'BUY',
                                 this.account['number'],
                                 this.data[this.showingGraphPosition][5].value,
                       'STOP',
                                 this.data[this.showingGraphPosition][8].value,
                  'SELL',
                                 this.data[this.showingGraphPosition][6].value,
                                 this.data[this.showingGraphPosition][4].value,
                    'STOP');
  }

  public putShortOrder(){
    this.orderService
        .putShortOrderWithTrigger(this.data[this.showingGraphPosition][2].value,
                                  this.data[this.showingGraphPosition][8].value,
                      'SELL_SHORT',
                                  this.account['number'],
                                  this.data[this.showingGraphPosition][6].value,
                        'STOP',
                                  this.data[this.showingGraphPosition][8].value,
                   'BUY',
                                  this.data[this.showingGraphPosition][5].value,
                                  this.data[this.showingGraphPosition][3].value,
                    'STOP');
  }

  public findRealCapital(){
    this.spinnerService.showSpinner.emit(true);
    this.accountService.getMyDefaultAccount().subscribe((data)=>{
      if(data){
        this.account = data;
        this.capital = data['realValue'];
        this.proposeRisk();
        this.spinnerService.showSpinner.emit(false);
      }
    },error => {
      this.spinnerService.showSpinner.emit(false);
    });
  }

  public getAssetType(){
    this.httpClient.get(this.config.api +  "/v1/api/stock/getAssetType").subscribe((data:any)=>{
      if(data){
        for (let item of data){
          this.assesType.push(
            {
              key: item.assetType,
              value: item.assetType,
              isSelected: false
            }
          );
        }
      }
    },
    error => {

    });
  }

  public areSomeAssetTypeSelected(){
    let isSelected =false;
    for (let item of this.assesType){
      if(item.isSelected){
        isSelected =  true;
      }
    }
    return isSelected;
  }

  public showGraph(){
    //Cleaning graph
    this.graphService.cleanGraph.emit();

    if(!this.modalService.isOpen){
      //Opening modal
      this.modalService.onOpen("fa-signal", "Graph", 'modal-full');
    }

    //Creating Graph
    let startDate = moment().add(-20,'year').format(this.config.date.format);
    let endDate = moment().format(this.config.date.format);
    this.graphService.timing.emit("_MONTH");
    this.graphService.investment_type = this.investmentType;
    this.graphService.startDate.emit(startDate);
    this.graphService.endDate.emit(endDate);
    this.graphService.ticket.emit(this.data[this.showingGraphPosition][2].value);

    //Drawing support and resistance
    let referenceA = this;
    this.chartReadySubscription = this.graphService.chartReady.subscribe(data=>{
      this.graphService.drawResistance.emit(+referenceA.data[referenceA.showingGraphPosition][3].value);
      this.graphService.drawSupport.emit(+referenceA.data[referenceA.showingGraphPosition][4].value);
    });
  }

  proposeRisk() {
    if (this.risk == "" && this.capital != "") {
      if (this.capital < 20000)
        this.risk = 2;
      else if (this.capital < 50000)
        this.risk = 1.33;
      else if (this.capital < 100000)
        this.risk = 1;
      else if (this.capital < 500000)
        this.risk = 0.5;
      else if (this.capital < 1000000)
        this.risk = 0.2;
      else if (this.capital >= 1000000)
        this.risk = 0.1;
    }
  }

  showPreviousGraph(){
    this.showingGraphPosition--;
    this.showGraph();
  }

  showNextGraph(){
    this.showingGraphPosition++;
    this.showGraph();
  }

  public requestOpportunityDataToApi(){
    this.data = [];
    let assetString = "";
    for (let i = 0; i < this.assesType.length; i++){
      if(this.assesType[i]['isSelected']){
        assetString += "'"+this.assesType[i]['value']+"'"+"|";
      }
    }
    if(this.investmentType && this.capital != '' && this.risk != '' && assetString != ""){
      assetString = assetString.slice(0, assetString.length - 1);
      this.spinnerService.showSpinner.emit(true);
      let url = this.config.api +  "/v1/api/opportunity-seeker/" + this.investmentType + "/" + this.capital + "/" + this.risk + "/" +
                this.paginatorStart + "/" + this.paginatorCount + "/" + assetString + "/" + ((this.symbol!=undefined && this.symbol!="")?this.symbol:"all") + "/" +
                this.showOnlyQEC + "/" + this.yearIPO;
      this.httpClient.get(url,{
        headers: {}
      })
       .subscribe((response:any)=>{
         this.spinnerService.showSpinner.emit(false);
         if(this.investmentType == "long"){
           if(response && response.total > 0){
             this.total = response.total;
             for(let item of response.result){
               let instrumentName = item.instrumentName;
               let instrumentAssetType = item.instrumentAssetType;
               let instrumentCode = item.instrumentCode;
               let resistance = item.opportunity_seeker_longResistance;
               let support = item.opportunity_seeker_longSupport;
               let buyPrice:number = item.opportunity_seeker_longBuyPrice;
               let sellPrice:number = item.opportunity_seeker_longSellPrice;
               let volume = item.opportunity_seeker_longVolume;
               let cantStock = item.cantStocks;
               let inversion =  item.inversionValue;
               let distance = item.distance;
               //['Name', 'Type', 'Code', 'Resistance', 'Support', 'Buy Price', 'Sell Price', 'Volume', 'Stocks', 'Inversion', 'Distance', 'Graph']
               this.data.push([
                 {type: 'string',   value:instrumentName},
                 {type: 'string',   value:instrumentAssetType},
                 {type: 'string',   value:instrumentCode},
                 {type: 'currency', value:resistance},
                 {type: 'currency', value:support},
                 {type: 'currency', value:buyPrice},
                 {type: 'currency', value:sellPrice},
                 {type: 'currency', value:volume},
                 {type: 'int',      value:cantStock},
                 {type: 'currency', value:inversion},
                 {type: 'percent',  value:distance},
                 {type: 'dropdown', instrumentCode: item.instrumentCode, resistance: item.opportunity_seeker_longResistance, support: item.opportunity_seeker_longSupport}
               ]);
             }
           }
         }
         else if(this.investmentType == "short"){
           if(response && response.total > 0){
             this.total = response.total;
             for(let item of response.result){
               let instrumentName = item.instrumentName;
               let instrumentAssetType = item.instrumentAssetType;
               let instrumentCode = item.instrumentCode;
               let resistance = item.opportunity_seeker_shortResistance;
               let support = item.opportunity_seeker_shortSupport;
               let buyPrice:number = item.opportunity_seeker_shortBuyPrice;
               let sellPrice:number = item.opportunity_seeker_shortSellPrice;
               let volume = item.opportunity_seeker_shortVolume;
               let cantStock = item.cantStocks;
               let inversion =  item.inversionValue;
               //['Name', 'Type', 'Code', 'Resistance', 'Support', 'Buy Price', 'Sell Price', 'Volume', 'Stocks', 'Inversion', 'Graph']
               this.data.push([
                 {type: 'string',   value:instrumentName},
                 {type: 'string',   value:instrumentAssetType},
                 {type: 'string',   value:instrumentCode},
                 {type: 'currency', value:resistance},
                 {type: 'currency', value:support},
                 {type: 'currency', value:buyPrice},
                 {type: 'currency', value:sellPrice},
                 {type: 'currency', value:volume},
                 {type: 'int',      value:cantStock},
                 {type: 'currency', value:inversion},
                 {type: 'dropdown', instrumentCode: item.instrumentCode, resistance: item.opportunity_seeker_shortResistance, support: item.opportunity_seeker_shortSupport}
               ]);
             }
           }
         }
       }, error=>{
         this.spinnerService.showSpinner.emit(false);
       });
    }
  }

  public goToPrevious(){
    this.paginatorStart = this.paginatorStart-this.paginatorCount;
    if(this.selectedButton == 1){
      this.labelButton1 -= 5;
      this.labelButton2 -= 5;
      this.labelButton3 -= 5;
      this.labelButton4 -= 5;
      this.labelButton5 -= 5;
      this.selectedButton = 5;
    }
    else{
      this.selectedButton -= 1;
    }
    this.requestOpportunityDataToApi()
  }

  public goTo(value){
    this.paginatorStart = ((this.paginatorCount*value)+1);
    this.selectedButton = value;
    this.requestOpportunityDataToApi();
  }

  public goToNext(){
    this.paginatorStart = this.paginatorStart+this.paginatorCount;
    if(this.selectedButton == 5){
      this.labelButton1 += 5;
      this.labelButton2 += 5;
      this.labelButton3 += 5;
      this.labelButton4 += 5;
      this.labelButton5 += 5;
      this.selectedButton = 1;
    }
    else{
      this.selectedButton += 1;
    }
    this.requestOpportunityDataToApi()
  }

  resetRisk(){
    this.risk = "";
    this.data = [];
  }

  resetCapital(){
    this.capital = "";
    this.data = [];
  }

  resetSymbol(){
    this.symbol = "";
    this.data = [];
  }

  resetFilters(){
    this.resetRisk();
    this.resetCapital();
    this.resetSymbol();
  }

  showFilterModalFunction(){
    this.showFilterModal = true;
  }

  getAssesTypeWording(){
    let wording = '';
    for(let i = 0; i < this.assesType.length; i++){
      if(this.assesType[i].isSelected){
        if(wording != ''){
          wording += ' | '
        }
        wording += this.assesType[i].value;
      }
    }
    return wording;
  }

}
