import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../services/config.service';
import {ToastrService} from 'ngx-toastr';
import {ModalConfirmationService} from '../common/modal-confirmation/modal-confirmation.service';
import {Subscription} from 'rxjs';
import {SpinnerService} from '../../services/spinner.service';
import {SecurityService} from '../../services/security.service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent implements OnInit {

  groups = [];
  permissionKeyword = "";
  newGroup = [];
  showingPermission:boolean = false;
  permissions:any = [];
  selectedGroup = null;

  constructor(private httpClient: HttpClient,
              private configService: ConfigService,
              private toast: ToastrService,
              private modalConfimationService: ModalConfirmationService,
              private spinnerService: SpinnerService,
              public securityService: SecurityService) { }

  ngOnInit() {
    this.findGroups();
  }

  public findGroups(){
    this.httpClient.get( this.configService.getLocalConfig().api + "/v1/api/group/findAll").subscribe((data:[]) => {
      this.groups = data;
      for (let item of this.groups){
        item.editName = false;
        item.editNameKey = false;
        item.editSystemGroup = false;
        item.editStatus = false;
        item.systemGroup = (item.systemGroup!='0');
      }
    }, error => {

    });
  }

  public enableNewGroup(){
    this.newGroup.push({
      "name": "",
      "nameKey": "",
      "systemGroup": false,
      "status": "ACTIVE"
    });
  }

  public createGroup(user, position){
    this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/group/create",{
      "name": user['name'],
      "nameKey": user['nameKey'],
      "systemGroup": user['systemGroup']?1:0,
      "status": user['status'],
    }).subscribe((data)=>{
      this.newGroup.splice(position, 1);
      this.findGroups();
      this.toast.success(data['message'], "Success");
    }, error => {
    });
  }

  public saveGroup(group, activity, position){
    this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/group/update",{
      "id": group['id'],
      "name": group['name'],
      "nameKey": group['nameKey'],
      "systemGroup": group['systemGroup']?1:0,
      "status": group['status'],
    }).subscribe((data)=>{
      this.toast.success(data['message'], "Success");
      this.groups[position][activity] = false;
    }, error => {
      this.toast.error(error['error'], "Error");
    });
  }

  public removeEmptyGroupFromList(position){
    this.newGroup.splice(position, 1);
  }

  public showPermission(group){
    this.permissions = [];
    this.selectedGroup = null;
    this.spinnerService.showSpinner.emit(true);
    this.httpClient.get(this.configService.getLocalConfig().api + '/v1/api/permission/findByGroup/' + group['id']).subscribe((data:any)=>{
      this.selectedGroup = group;
      for (let item of data){
        if(!this.permissions[item.groupName]){
          this.permissions[item.groupName] = [];
        }
        this.permissions[item.groupName].push(item);
      }
      this.showingPermission = true;
      this.spinnerService.showSpinner.emit(false);
    }, error=>{

    });
  }

  public changeSinglePermission(group, permission){
    this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/permission/changeSinglePermission",{
      "groupID":group.id,
      "permissionID":permission.id,
      "enabled":permission.enabled
    }).subscribe((data:any)=>{

    },error => {

    });
  }

  public remove(group){
    let modal:Subscription = this.modalConfimationService.onOpen("Do you want to remove this group?").subscribe(value=>{
      if (value){
        this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/group/delete", {
          "id":group['id']
        }).subscribe(data=>{
          this.toast.success(data['message'], "Success");
          this.findGroups();
        }, error => {
          this.toast.error(error['error'], "Error");
        });
      }
      modal.unsubscribe();
    });

  }

}
