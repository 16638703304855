import { Pipe } from '@angular/core';
import {isNull} from 'util';

@Pipe({
  name: 'nullValue'
})
export class NullValuePipe {
  transform(val, stringReplace) {
    if (stringReplace === undefined) {
      return val;
    }

    if (isNull(val) || val == '' || val == undefined) {
      return stringReplace;
    } else {
      return val;
    }
  }
}
