import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GraphService} from '../../graph/graph.service';
import * as moment from 'moment';
import {ConfigService} from '../../../services/config.service';
import {StockCalculatorAnalysisService} from './stock-calculator-analysis.service';
import {ToastrService} from 'ngx-toastr';
import {CompanySearchService} from '../../common/company-search/company-search.service';

@Component({
  selector: 'app-stock-calculator-adv',
  templateUrl: './stock-calculator-adv.component.html',
  styleUrls: ['./stock-calculator-adv.component.css']
})
export class StockCalculatorAdvComponent implements OnInit, OnDestroy {

  ticket: string;
  capital: any;
  risk: any;
  buy_price: any;
  resistance_price: any;
  support_price: any;
  sell_price: any;
  stock_qty: any;
  investment: any;
  startDate: string;
  endDate: string;
  localConfig: any;
  readyForCalculate: boolean;
  investment_type: string;
  errorMessage: string;
  isFullscreen: boolean = false;
  @ViewChild('graph', {static: false}) graph: ElementRef;

  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event) {
    this.isFullscreen = !this.isFullscreen;
    this.graphService.reDraw.emit();
  }

  constructor(private graphService: GraphService, private toast: ToastrService, private companySearchService: CompanySearchService, private configService: ConfigService) {
    this.localConfig = configService.getLocalConfig();
    this.resetForm();
    this.investment_type = "long";
    this.readyForCalculate = false;
    this.startDate = moment().add(-20,'year').format(this.localConfig.date.format);
    this.endDate = moment().format(this.localConfig.date.format);
    this.graphService.timing.emit("_MONTH");
    this.graphService.investment_type = this.investment_type;
  }

  ngOnInit() {
    this.graphService.cleanGraph.emit();
    this.graphService.timing.subscribe((value)=>{
      this.ticket = value;
    });
  }

  ngOnDestroy(): void {
    this.companySearchService.searchCriteria = null;
  }

  removeMessage(){
    this.errorMessage = "Testing";
  }


  public showRadio(value: string){
    this.investment_type = value;
  }

  calculate(){
    this.readyForCalculate = false;
    if(this.capital && this.risk && this.resistance_price && this.support_price && this.ticket){
      if(this.investment_type == "long")
        this.calculateLong();
      else{
        this.calculateShort();
      }
    }
    else{
      this.readyForCalculate = false;
    }
  }

  calculateLong(){
    if(+this.resistance_price <= +this.support_price){
      this.toast.error("The resistance cannot be less than the support", "Error");
      return;
    }
    if(+this.risk <= 0 || +this.risk > 100){
      this.toast.error("Risk must be greater than zero and less than equal to 100", "Error");
      return;
    }
    this.stock_qty =  Math.floor((+this.capital*+this.risk/100)/(+this.resistance_price-+this.support_price));
    this.buy_price = (+this.resistance_price + 0.01).toFixed(2);;
    this.sell_price = (+this.support_price - (3*(+this.support_price/100))).toFixed(2);;
    this.investment = (+this.stock_qty * +this.buy_price).toFixed(2);
    this.readyForCalculate = true;
  }

  calculateShort(){
    if(+this.resistance_price <= +this.support_price){
      this.toast.error("The resistance cannot be less than the support", "Error");
      return;
    }
    if(+this.risk <= 0 || +this.risk > 100){
      this.toast.error("Risk must be greater than zero and less than equal to 100", "Error");
      return;
    }
    this.stock_qty =  Math.floor((+this.capital*+this.risk/100)/(+this.resistance_price-+this.support_price));
    this.buy_price = (+this.resistance_price + (3*(+this.resistance_price/100))).toFixed(2);;
    this.sell_price = (+this.support_price - 0.01).toFixed(2);;
    this.investment = (+this.stock_qty * +this.sell_price).toFixed(2);
    this.readyForCalculate = true;
  }

  investmentTypeToggle(){
  }

  proposeRisk() {
    if (this.risk == "" && this.capital != "") {
      if (this.capital < 20000)
        this.risk = 2;
      else if (this.capital < 50000)
        this.risk = 1.33;
      else if (this.capital < 100000)
        this.risk = 1;
      else if (this.capital < 500000)
        this.risk = 0.5;
      else if (this.capital < 1000000)
        this.risk = 0.2;
      else if (this.capital >= 1000000)
        this.risk = 0.1;
    }
  }

  onGraphTicket(){
    this.resetForm();
    this.graphService.ticket.emit(this.ticket);
    this.graphService.startDate.emit(this.startDate);
    this.graphService.endDate.emit(this.endDate);
  }

  drawResistance(drawByClick: boolean){
    if(!this.ticket)
      return;
    if((this.resistance_price == "" || this.support_price == "") || (+this.resistance_price>this.support_price)){
      if(drawByClick && this.resistance_price == ""){
        this.graphService.investment_type = this.investment_type;
        this.graphService.drawResistance.emit(0);
        this.graphService.resistance.subscribe((value)=>{
          if(this.support_price && value<=this.support_price){
            this.removeResistance();
            this.toast.error("The resistance cannot be less than the support", "Error");
            return;
          }
          this.resistance_price = value.toFixed(2);
          this.calculate();
        });
      }
      else if(!drawByClick && this.resistance_price != ""){
        this.graphService.investment_type = this.investment_type;
        this.graphService.drawResistance.emit(+this.resistance_price);
      }
      else{
        this.removeResistance();
      }
      this.errorMessage = null;
    }
    else{
      this.toast.error("The resistance cannot be less than the support", "Error");
      return;
    }
  }

  removeResistance(){
    this.graphService.removeResistance.emit(true);
  }

  drawSupport(drawByClick: boolean){
    if(!this.ticket)
      return;
    if((this.resistance_price == "" || this.support_price == "") || (+this.resistance_price>this.support_price)){
      if(drawByClick && this.support_price == ""){
        this.graphService.investment_type = this.investment_type;
        this.graphService.drawSupport.emit(0);
        this.graphService.support.subscribe((value)=>{
          if(this.resistance_price && value>=this.resistance_price){
            this.removeSupport();
            this.toast.error("The resistance cannot be less than the support", "Error");
            return;
          }
          this.support_price = value.toFixed(2);
          this.calculate()
        });
      }
      else if(!drawByClick && this.support_price != ""){
        this.graphService.investment_type = this.investment_type;
        this.graphService.drawSupport.emit(+this.support_price);
      }
      else{
        this.removeSupport();
      }
      this.errorMessage = null;
    }
    else{
      this.toast.error("The resistance cannot be less than the support", "Error");
      return;
    }
  }

  removeSupport(){
    this.graphService.removeSupport.emit(true);
  }

  resetRisk(){
    this.risk = "";
  }

  resetCapital(){
    this.capital = "";
  }

  fullScreen(){
    if(!this.isFullscreen){
      if (this.graph.nativeElement.requestFullscreen) {
        this.graph.nativeElement.requestFullscreen();
      } else if (this.graph.nativeElement.mozRequestFullScreen) { /* Firefox */
        this.graph.nativeElement.mozRequestFullScreen();
      } else if (this.graph.nativeElement.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        this.graph.nativeElement.webkitRequestFullscreen();
      } else if (this.graph.nativeElement.msRequestFullscreen) { /* IE/Edge */
        this.graph.nativeElement.msRequestFullscreen();
      }
    }
    else {
      if (this.graph.nativeElement.exitFullscreen) {
        this.graph.nativeElement.exitFullscreen();
      } else if (this.graph.nativeElement.mozCancelFullScreen) {
        /* Firefox */
        this.graph.nativeElement.mozCancelFullScreen();
      } else if (this.graph.nativeElement.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.graph.nativeElement.webkitExitFullscreen();
      } else if (this.graph.nativeElement.msExitFullscreen) {
        /* IE/Edge */
        this.graph.nativeElement.msExitFullscreen();
      }
    }
    this.graphService.reDraw.emit();
  }

  resetForm(){
    this.risk = "";
    this.capital = "";
    this.support_price = "";
    this.resistance_price = "";
    this.stock_qty = "";
    this.investment = "";
    this.buy_price = "";
    this.sell_price = "";
    this.readyForCalculate = false;
    this.removeResistance();
    this.removeSupport();
  }

  loadAnalysis(){

  }

  saveAnalysis(){
    let analysisService =  new StockCalculatorAnalysisService();
    let config = {
      ticket:this.ticket,
      capital:this.capital,
      risk: this.risk,
      startDate: this.startDate,
      support_price: this.support_price,
      resistance_price: this.resistance_price,
      stock_qty: this.stock_qty,
      investment: this.investment,
      buy_price: this.buy_price,
      sell_price: this.sell_price,
      readyForCalculate: this.readyForCalculate
    }
  }

}
