import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../services/config.service';
import {SpinnerService} from '../../services/spinner.service';
import {AccountService} from '../account/account.service';
import {GraphModalService} from '../common/graph-modal/graph-modal.service';
import {isNull} from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.css']
})
export class OperationComponent implements OnInit, OnDestroy {

  autoRefresh:boolean = true;
  autoRefreshInterval:any;

  operationList:any = [];
  riskQtyTotal:number = 0;
  riskPercentTotal:number = 0;
  investmentTotal:number = 0;
  floatCapTotal:number = 0;
  realCapTotal:number = 0;
  plOpenTotal:number = 0;
  plPercentTotal:number = 0;

  constructor(private httpClient: HttpClient,
              private configService: ConfigService,
              private spinnerService: SpinnerService,
              private accountService: AccountService,
              private graphModalService: GraphModalService) { }

  ngOnInit() {
    this.findOperations();
    if(this.autoRefresh)
      this.startAutoRefreshInterval();
  }

  ngOnDestroy(): void {
    this.stopAutoRefreshInterval();
  }

  public autoRefreshToggle(){
    this.autoRefresh = !this.autoRefresh;
    if(this.autoRefresh){
      this.startAutoRefreshInterval();
    }
    else{
      this.stopAutoRefreshInterval();
    }
  }

  public startAutoRefreshInterval(){
    let thisReference = this;
    this.autoRefreshInterval = setInterval(function() {
      thisReference.findOperations();
    },60000);
  }

  public stopAutoRefreshInterval(){
    clearInterval(this.autoRefreshInterval);
  }

  findOperations(){
    if(this.operationList.length == 0)
      this.spinnerService.showSpinner.emit(true);
    this.accountService.getMyDefaultAccount().subscribe((account:any)=>{
      this.httpClient.get(this.configService.getLocalConfig().api +
                              "/v1/api/operation/find-open-operation-by-account/" +
                              account['number']).subscribe((data:any)=>{
        this.cleanVariables();
        this.operationList = data;
        let plPercentSum = 0
        let riskPercentSum = 0
        for (let item of data){
          if(item['riskQty']!=null)
            this.riskQtyTotal +=  parseFloat(item['riskQty']);
          if(item['investment']!=null)
            this.investmentTotal += parseFloat(item['investment']);
          if(item['floatCap']!=null)
            this.floatCapTotal += parseFloat(item['floatCap']);
          if(item['realCap']!=null)
            this.realCapTotal += parseFloat(item['realCap']);
          if(item['plOpen']!=null)
            this.plOpenTotal += parseFloat(item['plOpen']);
          if(item['riskPercent']!=null)
            riskPercentSum += parseFloat(item['riskPercent']);
          if(item['plPercent']!=null)
            plPercentSum += parseFloat(item['plPercent']);
        }
        this.riskPercentTotal = riskPercentSum/data.length;
        this.plPercentTotal = plPercentSum/data.length;

        this.spinnerService.showSpinner.emit(false);
      }, error => {
        this.spinnerService.showSpinner.emit(false);
      });
    });
  }

  public cleanVariables(){
    this.riskQtyTotal = 0;
    this.investmentTotal = 0;
    this.floatCapTotal = 0;
    this.realCapTotal = 0;
    this.plOpenTotal = 0;
    this.riskPercentTotal = 0;
  }

  public showGraph(item){
    this.graphModalService.openModal('Graph', 'fa-signal', item.code, 'MONTH');
  }

}
