import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'input-inline',
  templateUrl: './input-inline.component.html',
  styleUrls: ['./input-inline.component.css']
})
export class InputInlineComponent implements OnInit {

  @Input() width: string = "100%";
  @Input() value: string = "";
  oldValue:string;
  @Input() inputStatus: string = "";
  @Output() valueChange = new EventEmitter<string>();
  @Output('accept') accept = new EventEmitter<void>();
  @Output('cancel') cancel = new EventEmitter<void>();
  @Input() placeholder: string = "Type...";

  constructor() { }

  ngOnInit() {
    this.oldValue = _.clone(this.value);
  }

  acceptEvent(){
    if(this.value.length == 0){
      this.inputStatus = "has-error";
      return;
    }
    this.valueChange.emit(this.value);
    this.accept.emit();
  }

  cancelEvent(){
    this.value = this.oldValue;
    this.cancel.emit();
  }

}
