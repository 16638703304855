import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SpinnerService {

  showSpinner = new EventEmitter<boolean>();

  constructor(){}
}
