import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../services/config.service';
import {AccountService} from '../account/account.service';
import {MyDateService} from '../../services/myDate.service';
import {SpinnerService} from '../../services/spinner.service';
import {ToastrService} from 'ngx-toastr';
import {ModalConfirmationService} from '../common/modal-confirmation/modal-confirmation.service';
import {Subscription} from 'rxjs';
import {OrderService} from './order.service';
import {GraphModalService} from '../common/graph-modal/graph-modal.service';

@Component({
  selector: 'order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit, OnDestroy {

  orderList:any = [];

  putOrderSubscription:Subscription;

  //Filters
  account:any;
  entered: Date[] = [];
  closed: Date[] = [];
  orderStatus = [
    {key:"AWAITING_PARENT_ORDER",value: "Awaiting Parent Order",isSelected: false},
    {key:"AWAITING_CONDITION",value: "Awaiting Condition",isSelected: false},
    {key:"AWAITING_STOP_CONDITION",value: "Awaiting Stop Condition",isSelected: false},
    {key:"AWAITING_MANUAL_REVIEW",value: "Awaiting Manual Review",isSelected: false},
    {key:"ACCEPTED",value: "Accepted",isSelected: false},
    {key:"AWAITING_UR_OUT",value: "Awaiting or out",isSelected: false},
    {key:"PENDING_ACTIVATION",value: "Pending Activation",isSelected: true},
    {key:"QUEUED",value: "Queue",isSelected: true},
    {key:"WORKING",value: "Working",isSelected: true},
    {key:"REJECTED",value: "Rejected",isSelected: false},
    {key:"PENDING_CANCEL",value: "Pending Cancel",isSelected: false},
    {key:"CANCELED",value: "Canceled",isSelected: false},
    {key:"PENDING_REPLACE",value: "Pending Replace",isSelected: false},
    {key:"REPLACED",value: "Replaced",isSelected: false},
    {key:"FILLED",value: "Filled",isSelected: true},
    {key:"EXPIRED",value: "Expired",isSelected: false},
    {key:"NEW",value: "New",isSelected: false},
    {key:"AWAITING_RELEASE_TIME",value: "Awaiting Release Time",isSelected: false},
    {key:"PENDING_ACKNOWLEDGEMENT",value: "Pending Acknowledgement",isSelected: false},
    {key:"PENDING_RECALL",value: "Pending Recall",isSelected: false},
    {key:"UNKNOWN",value: "Unknown",isSelected: false}
  ];

  @Input() resetFilter:boolean = false;

  constructor(private httpClient: HttpClient,
              private configService: ConfigService,
              private accountService: AccountService,
              private myDateService: MyDateService,
              private spinnerService: SpinnerService,
              private toastrService: ToastrService,
              private confirmationService: ModalConfirmationService,
              private orderService: OrderService,
              private graphModalService: GraphModalService) { }

  ngOnInit() {
    this.entered = [(new Date())];
    this.closed = [(new Date())];
    this.findOrders();
  }

  ngOnDestroy(): void {

  }

  public findOrders(showSpinner=true){
    if(showSpinner)
      this.spinnerService.showSpinner.emit(true);
    this.accountService.getMyDefaultAccount().subscribe((data:any)=>{
      this.account = data;
      this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/order/find-orders",{
        'enteredFrom':((this.entered.length == 2 && this.entered[0])?this.myDateService.getFormatedDate(this.entered[0]):null),
        'enteredTo':((this.entered.length == 2 && this.entered[1])?this.myDateService.getFormatedDate(this.entered[1]):null),
        'closedFrom':((this.closed.length == 2 && this.closed[0])?this.myDateService.getFormatedDate(this.closed[0]):null),
        'closedTo':((this.closed.length == 2 && this.closed[1])?this.myDateService.getFormatedDate(this.closed[1]):null),
        'orderType':this.fixOrderStatusForRequest(),
        'accountNumber':this.account['number']
      }).subscribe((data:any)=>{
        this.orderList = data;
        if(showSpinner)
          this.spinnerService.showSpinner.emit(false);
      }, error => {
        if(showSpinner)
          this.spinnerService.showSpinner.emit(false);
      });
    }, error => {
      if(showSpinner)
        this.spinnerService.showSpinner.emit(false);
    });
  }

  public fixOrderStatusForRequest(){
    let assetString = "";
    for (let i = 0; i < this.orderStatus.length; i++){
      if(this.orderStatus[i]['isSelected']){
        assetString += "'"+this.orderStatus[i]['key']+"'"+"|";
      }
    }
    assetString = assetString.slice(0, assetString.length - 1);
    return ((assetString!="")?assetString:null);
  }

  public putLongOrder(ticket, quantity, directionality, accountNumber, price, orderType){
    this.orderService.putLongOrder(ticket, quantity, directionality, accountNumber, price, orderType);
  }

  public cancelOrder(order){
    let orderCancelCompletedSubscription = this.orderService.orderCancelCompleted.subscribe(data=>{
      this.findOrders(false);
      orderCancelCompletedSubscription.unsubscribe();
    });
    this.orderService.cancelOrder(order.accountNumber, order.orderId);
  }

  public resetFilterAction(){
    this.entered = [(new Date())];
    this.closed = [(new Date())];
    for (let item of this.orderStatus){
      item.isSelected = false;
    }
    this.resetFilter = true;
    this.findOrders();
  }

  public showGraph(item){
    this.graphModalService.openModal('Graph', 'fa-signal', item.code, 'MONTH');
  }

}
