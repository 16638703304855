import {Directive, ElementRef, HostBinding, Renderer2} from '@angular/core';

@Directive({
  selector: '[alert-notification]'
})
export class AlertNotificationDirective {
  @HostBinding('class.alert-notification-visible') showMessage = false;
  @HostBinding('class.alert-notification-hidden') hiddenMessage = true;
  alertType: string = "info";

  constructor(el: ElementRef, render: Renderer2) {

  }
}
