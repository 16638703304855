import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParticipationModalService {

  //This variable will notify to parent component for refreshing data
  needToReload = new EventEmitter<boolean>();
}
