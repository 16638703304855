import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate{

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
      Observable<boolean
      |UrlTree>
      |Promise<boolean
      |UrlTree>
      |boolean
      |UrlTree
  {
    let isAuth = this.authService.isAuth;
    if(isAuth)
      return true;
    else
      return this.router.navigate(['/auth']);
  }

}
