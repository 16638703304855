import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import * as Highcharts from "highcharts/highcharts";
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../services/config.service';
import {AccountService} from '../account/account.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  @ViewChild('walletHistoric', {static:false}) walletHistoric: ElementRef;
  isFullScreen:boolean = false;
  instrumentBase:{code:string, name:string} = {code: "defaultAccount", name:"defaultAccount"};
  indexInstrumentCode = [{code:"SPY", name:"S&P 500 INDEX"}, {code:"QQQ", name:"NASDAQ-100 INDEX INDEX"}];

  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event) {
    this.isFullScreen = !this.isFullScreen;
  }

  constructor(private toastr: ToastrService,
              private httpClient: HttpClient,
              private configService: ConfigService,
              private accountService: AccountService) { }

  ngOnInit() {

  }

  public fullScreen(element: HTMLElement){
    if(!this.isFullScreen){
      if (element.requestFullscreen)
        element.requestFullscreen();
    }
    else {
      if (document.exitFullscreen)
        document.exitFullscreen();
    }
  }

}
