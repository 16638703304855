import {Component} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {SpinnerService} from '../services/spinner.service';
import {AuthService} from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  isAuth: boolean = false;

  constructor(private spinner: NgxSpinnerService, private spinnerService: SpinnerService, private authService: AuthService) {
    this.spinnerService.showSpinner.subscribe((value)=>{
      if(value)
        this.spinner.show();
      else
        this.spinner.hide();
    });
    this.authService.isAuthToggle.subscribe((value)=>{
      this.isAuth = value;
    });
    this.isAuth = this.authService.isAuth;
  }

}
