import {Component, Input, OnInit} from '@angular/core';
import {AccountService} from '../../account/account.service';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';
import HAccessibility from "highcharts/modules/accessibility";
import Highcharts3D from "highcharts/highcharts-3d";
import * as Highcharts from 'highcharts';
import {createElementCssSelector} from '@angular/compiler';

HAccessibility(Highcharts);
Highcharts3D(Highcharts);

@Component({
  selector: 'app-wallet-pie-chart',
  templateUrl: './wallet-pie-chart.component.html',
  styleUrls: ['./wallet-pie-chart.component.css']
})
export class WalletPieChartComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  searchCompleted: boolean = false;

  constructor(private accountService: AccountService,
              private httpClient: HttpClient,
              private configService: ConfigService) { }

  ngOnInit() {
    this.loadPieChartWallet();
  }

  @Input() showInFullScreenMode(fullscreen: boolean){

  }

  public loadPieChartWallet(){
    this.accountService.getMyDefaultAccount().subscribe((data: any)=>{
      if(data && data['id']){
        this.searchCompleted = false;
        this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/account/get-positions-for-graph/" + data['id']).subscribe((response:any)=>{
          let dataValue = [];

          if(response && response.length > 0){
            dataValue.push({
              name: 'Buying Power',
              code: 'Buying Power',
              y: (100*response[0]['buyingPower'])/response[0]['floatValue'],
              quantity: response[0]['buyingPower'],
              investment:0,
              averagePrice:0,
              profit:0,
              plOpen:0,
            });

            for(let item of response){
              dataValue.push({
                code: item['instrumentCode'],
                name: item['instrumentName'],
                y: (100*item['quantity']*item['lastPrice'])/item['floatValue'],
                sliced: true,
                selected: true,
                quantity: item['quantity'],
                investment: (item['quantity']*item['averagePrice']).toFixed(2),
                averagePrice: item['averagePrice'],
                profit: item['profit'],
                plOpen: item['plOpen']
              });
            }
          }
          else{
            dataValue.push({
              name: 'Buying Power',
              code: 'Buying Power',
              y: 100,
              quantity: data['buyingPower'],
              investment:0,
              averagePrice:0,
              profit:0,
              plOpen:0
            });
          }

          let options:any = {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie',
              options3d: {
                enabled: true,
                alpha: 45,
                beta: 0
              }
            },
            title: {
              text: ''
            },
            tooltip: {
              pointFormat:
                'Percent: <b>{point.percentage:.1f}%</b><br>' +
                'Quantity: <b>{point.quantity}</b><br>' +
                'Investment: <b>${point.investment}</b><br>' +
                'Average Price: <b>${point.averagePrice}</b><br>' +
                'Profit: <b>${point.profit}</b><br>' +
                'P/L Open: <b>{point.plOpen:.1f}%</b>'
            },
            accessibility: {
              point: {
                valueSuffix: '%'
              }
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                depth: 35,
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.code}</b>: {point.percentage:.1f} %'
                }
              }
            },
            series: [{
              name: 'Brands',
              colorByPoint: true,
              data: dataValue
            }]
          };
          this.Highcharts.chart('containerWalletPieChart',options);
        });
        this.searchCompleted = true;
      }
    }, error => {

    });
  }

}
