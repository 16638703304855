import {Component, Input, OnInit} from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {HttpClient} from '@angular/common/http';
import {SpinnerService} from '../../../services/spinner.service';
import {ToastrService} from 'ngx-toastr';
import {GraphModalService} from '../../common/graph-modal/graph-modal.service';

@Component({
  selector: 'top-loser',
  templateUrl: './top-loser.component.html',
  styleUrls: ['./top-loser.component.css']
})
export class TopLoserComponent implements OnInit {

  @Input() limit: any = 10;
  @Input() buttonSizeClass = "btn-xs";
  @Input() timing = "WEEK";
  isExpanded: boolean = false;
  searchCompleted: boolean = false;

  loserList = [];

  constructor(private configService: ConfigService,
              private httpClient: HttpClient,
              private graphModalService: GraphModalService) { }

  ngOnInit() {
    this.getTopLosers();
  }

  public getTopLosers(){
    this.loserList = []
    this.searchCompleted = false;
    this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/stock/get-top-losers/" + this.limit + "/" + this.timing).subscribe((data:any)=>{
      this.loserList = data;
      this.searchCompleted = true;
    }, error => {

    });
  }

  public showGraph(item, timing = 'MONTH'){
    this.graphModalService.openModal('Graph', 'fa-signal', item.code, timing);
  }

}
