import { Pipe } from '@angular/core';
import {isNull} from 'util';

@Pipe({
  name: 'removeLodash'
})
export class RemoveLodashPipe {
  transform(value, oldCharacter, newCharacter) {
    return value.replace(new RegExp(oldCharacter, 'g'),newCharacter);
  }
}
