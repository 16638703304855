import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {GraphService} from '../../graph/graph.service';
import {ConfigService} from '../../../services/config.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {SpinnerService} from '../../../services/spinner.service';
import {CompanySearchService} from './company-search.service';

@Component({
  selector: 'company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.css']
})
export class CompanySearchComponent implements OnInit {

  symbol: string;
  config: any;
  @ViewChild('criteria', {static: true}) criteriaComponent: ElementRef;
  isSearching: boolean = false;
  result: any = [];
  @Input() returnResultOnly: boolean = false;
  @Output() selectedCompany = new EventEmitter<{id: string, code: string, name: string}>();
  @Input() showSpinner: boolean = true;

  constructor(private graphService: GraphService,
              private router: Router,
              private configService: ConfigService,
              private httpClient: HttpClient,
              private spinnerService: SpinnerService,
              private companySearchService: CompanySearchService) {
    this.config = this.configService.getLocalConfig();
    this.isSearching = false;
  }

  @HostListener('document:click')
  clickout() {
    this.result = [];
    this.criteriaComponent.nativeElement.value = "";
  }

  ngOnInit() {
  }

  public searchCompany(value: string){
    if(value == null || value == "" || value.length < 3){
      this.result = [];
      return;
    }
    this.isSearching = true;

    if(this.showSpinner)
      this.spinnerService.showSpinner.emit(true);

    this.httpClient.get(this.config.api + "/v1/api/stock/searchByKeyword/" + value).subscribe((data: any)=>{
      if(data && Array.isArray(data) && data.length > 0){
        this.result = data;
      }
      if(this.showSpinner)
        this.spinnerService.showSpinner.emit(false);

      this.isSearching = false;

    },(error)=>{
      if(this.showSpinner)
        this.spinnerService.showSpinner.emit(false);

      this.isSearching = false;
    });
  }

  public selectCompany(company: {id: string, code: string, name: string}){
    if(this.router.url === '/chart' || this.router.url === '/stock-calculator-advance'){
      this.graphService.ticket.emit(company.code);
      this.graphService.timing.emit('_MONTH');
      this.graphService.startDate.emit("1700-01-01");
      this.graphService.endDate.emit(moment().format(this.config.date.format));
      this.result = [];
      this.criteriaComponent.nativeElement.value = "";
    }
    else if(!this.returnResultOnly){
      this.companySearchService.searchCriteria = {
        code: company.code,
        timing: '_D',
        startDate: "1700-01-01",
        endDate: moment().format(this.config.date.format)
      };
      this.result = [];
      this.criteriaComponent.nativeElement.value = "";
      this.router.navigate(['/chart']);
    }
    else if(this.returnResultOnly){
      this.result = [];
      this.criteriaComponent.nativeElement.value = "";
      this.selectedCompany.emit(company);
    }
  }

}
