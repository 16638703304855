import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalConfirmationService} from './modal-confirmation.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.css']
})
export class ModalConfirmationComponent implements OnInit, OnDestroy {

  @Input() showModal: boolean = false;
  question: string = "";

  //Subscriptions
  showModalSubscription: Subscription;
  questionSubscription: Subscription;

  constructor(private modalConfirmationService: ModalConfirmationService) { }

  ngOnInit() {
    this.showModalSubscription = this.modalConfirmationService.showModal.subscribe(value=>{
      this.showModal = value;
    });

    this.questionSubscription = this.modalConfirmationService.questionEvent.subscribe(value=>{
      this.question = value;
    });
  }

  ngOnDestroy(){
    this.showModalSubscription.unsubscribe();
    this.questionSubscription.unsubscribe();
  }

  public accept(){
    this.modalConfirmationService.response.emit(true);
    this.onClose();
  }

  public cancel(){
    this.modalConfirmationService.response.emit(false);
    this.onClose();
  }

  onClose(){
    this.modalConfirmationService.showModal.emit(false);
  }

}
