import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  showModal = new EventEmitter<boolean>();
  icon = new EventEmitter<string>();
  title = new EventEmitter<string>();
  modalType = new EventEmitter<string>();
  isOpen = false;

  onOpen(icon:string = "fa-signal", title:string = "Example", modalType:string = "modal-small"){
    this.showModal.emit(true);
    this.icon.emit(icon);
    this.title.emit(title);
    this.modalType.emit(modalType);
    this.isOpen = true;
  }

  onClose(){
    this.showModal.emit(false);
    this.isOpen = false;
  }
}
