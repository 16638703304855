import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthService} from './auth/auth.service';
import {User} from './auth/user.module';
import {ConfigService} from '../services/config.service';
import {catchError, retry} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  config:any;
  constructor(private authService: AuthService,
              private configService: ConfigService,
              private toast: ToastrService) {
    this.config = this.configService.getLocalConfig();
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //Working on headers
    let headers: any = {
      "Content-Type": 'application/json',
      "Access-Control-Allow-Origin": this.config.api
    };
    if(this.authService.getUser() && this.authService.getUser() instanceof User) {
      let user: User = this.authService.getUser();
      if(user.token){
        headers.Token = user.token;
      }
    }
    const requestWithAuth = req.clone({
      setHeaders: headers
    });
    return next.handle(requestWithAuth).pipe(
      catchError((error: HttpErrorResponse)=>{
        //If the token is invalid then logout
        if(error.error.code && error.error.code == 401){
          this.toast.error(error.error.error, "Unauthorized");
        }
        else if(error.error.code && error.error.code == 404){
          this.toast.info(error.error.error, "Error");
        }
        else if(error.error.error && !Array.isArray(error.error.error)){
          this.toast.error(error.error.error, "Error");
        }
        else if(error.error.error && Array.isArray(error.error.error)){
          for (let item of error.error.error){
            this.toast.error(item.message, "Error");
          }
        }
        return throwError(error);
      }));
  }

}
