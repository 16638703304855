import {Component, OnInit, EventEmitter, Output, OnDestroy} from '@angular/core';
import * as _ from 'lodash';
import {AuthService} from '../auth/auth.service';
import {TaskService} from '../task/task.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {SecurityService} from '../../services/security.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit, OnDestroy {
  symbol = '';
  taskServiceSubscription: Subscription;
  taskList = [];
  constructor( private authService: AuthService,
               private taskService: TaskService,
               private router: Router,
               public securityService: SecurityService) {}

  ngOnInit() {
    this.showTask();
  }

  ngOnDestroy(): void {
    this.taskServiceSubscription.unsubscribe();
  }

  public showTask(){
    this.taskServiceSubscription = this.taskService.task.subscribe((data)=>{
      this.taskList = data;
    });
  }

  public navigateToDashboard(){
    if(this.securityService.checkPermissionByNameKey('VD')){
      this.router.navigate(['dashboard']);
    }
  }

  public checkPermissionByNameKey(nameKey){

  }

  logout(){
    this.authService.logout();
  }

}
