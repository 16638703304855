import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {HttpClient} from '@angular/common/http';
import {AccountService} from '../../account/account.service';
import {SpinnerService} from '../../../services/spinner.service';
import {ParticipationModalService} from './participation-modal/participation-modal.service';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'account-mapping',
  templateUrl: './account-mapping.component.html',
  styleUrls: ['./account-mapping.component.css']
})
export class AccountMappingComponent implements OnInit, OnDestroy {

  timing: any = "ALL";
  account:{
    id:string,
    number: string
  } = null;
  accountList:any=[];
  showAddContributionModal:boolean = false;
  showAddRetributionModal:boolean = false;
  participationList:{
    memberId:string,
    accountId: string,
    memberFullName:string,
    amount:number,
    participationPercent: number,
    profitLostAmount: number
  }[]=[];
  participationListPieGraph:any = [];
  contributionRetributionByMember: {
    id:string,
    accountId: string,
    memberId: string,
    foundId: string,
    description: string,
    date: string,
    amount: number,
    isActive: boolean,
    type: string
  }[] = null;

  contributionModalServiceSubscription: Subscription;
  selectMemberRow:{
    memberId:string,
    accountId: string,
    memberFullName:string,
    amount:number,
    participationPercent: number,
    profitLostAmount: number
  } = null;
  memberAccountFloatingProfit = [];
  memberAccountRealProfit = [];


  showFloatingGraph: boolean = true;


  constructor(private configService: ConfigService,
              private httpClient: HttpClient,
              private accountService: AccountService,
              private spinnerService: SpinnerService,
              private participationModalService: ParticipationModalService,
              private toast: ToastrService) { }

  ngOnInit() {
    this.contributionModalServiceSubscription = this.participationModalService.needToReload.subscribe((data:any)=>{
      if(this.account != null){
        this.searchParticipationByAccount(this.account.id);
        this.findParticipationByMemberAndAccount();
      }
    });
    this.getMyAccounts();
  }

  ngOnDestroy(): void {
    this.contributionModalServiceSubscription.unsubscribe();
  }

  public getMyAccounts(){
    this.accountService.getMyAccounts().subscribe((data:any)=>{
      if(data !== 'undefined' && data.length >0){
        for (let item of data){
          if(item.isActive == 'true'){
            this.accountList.push({
              id: item.id,
              number: item.number
            });
            if(item.isDefault = 'true'){
              this.account = {
                id: item.id,
                number: item.number
              }
              this.searchParticipationByAccount(this.account.id);
            }
          }
        }
      }
    },error => {

    });
  }

  public addContribution(){
    this.showAddContributionModal = true;
  }

  public addRetribution(){
    this.showAddRetributionModal = true;
  }

  public selectAccount(acc:{id:string, number:string}){
    this.account = acc;
    this.searchParticipationByAccount(this.account.id);
  }

  public searchParticipationByAccount(accountId:string, showSpinner = true){
    this.participationList = [];
    this.participationListPieGraph = [];
    if(showSpinner)
      this.spinnerService.showSpinner.emit(true);
    this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/member/search-participation-by-account/" + accountId).subscribe((data:any)=>{
      if(data !== "undefined" && data != null){
        for (let item of data){
          let part = {
            memberId:item.memberId,
            accountId: item.accountId,
            memberFullName: item.memberFullName,
            amount:item.amount,
            participationPercent: Number(item.participationPercent),
            profitLostAmount: item.profitLostAmount
          };
          this.participationList.push(part);
        }
        this.showParticipationPieGraph();
      }
      if(showSpinner)
        this.spinnerService.showSpinner.emit(false);
    },error => {
      if(showSpinner)
        this.spinnerService.showSpinner.emit(false);
    });
  }

  public showParticipationPieGraph(){
      for(let item of this.participationList){
        this.participationListPieGraph.push({
          'y':item.participationPercent,
          'name':item.memberFullName,
          'amount': item.amount,
          'profitLostAmount': (Number)(item.profitLostAmount).toFixed(2)
        });
      }
  }

  public showMemberContributionDetails(memberContribution:{
    memberId:string,
    accountId: string,
    memberFullName:string,
    amount:number,
    participationPercent: number,
    profitLostAmount: number
  }){
    if(this.selectMemberRow == null){
      this.selectMemberRow = memberContribution;
      this.findParticipationByMemberAndAccount();
      if(this.showFloatingGraph)
        this.getMemberAccountHistoricProfitByAccountAndMember();
      else
        this.getRealProfitByAccountAndMember();
    }
    else if(this.selectMemberRow.memberId == memberContribution.memberId){
      this.selectMemberRow = null;
      this.contributionRetributionByMember = null;
    }
    else{
      this.selectMemberRow = memberContribution;
      this.contributionRetributionByMember = null;
      this.findParticipationByMemberAndAccount();
      if(this.showFloatingGraph)
        this.getMemberAccountHistoricProfitByAccountAndMember();
      else
        this.getRealProfitByAccountAndMember();
    }
  }

  public findParticipationByMemberAndAccount(){
    this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/member/find-participation-by-member-and-account/" + this.account.id + "/" + this.selectMemberRow.memberId).subscribe((data: any)=>{
      for (let item of data){
        item.isActive = ((item.isActive == "1")?true:false);
      }
      this.contributionRetributionByMember = data;
    }, error => {
      this.contributionRetributionByMember = null;
    });
  }

  public deleteContribution(id){
    this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/member/delete-contribution",{
      id: id
    }).subscribe((data:any)=>{
      this.toast.success(data['message'], "Success");
      this.contributionRetributionByMember = null;
      this.findParticipationByMemberAndAccount();
      this.searchParticipationByAccount(this.account.id, false);
    }, error => {

    });
  }

  public deleteRetribution(id){
    this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/member/delete-retribution",{
      id: id
    }).subscribe((data:any)=>{
      this.toast.success(data['message'], "Success");
      this.contributionRetributionByMember = null;
      this.findParticipationByMemberAndAccount();
      this.searchParticipationByAccount(this.account.id, false);
    }, error => {

    });
  }

  public toggleParticipationStatus(item:{
    id:string,
    accountId: string,
    memberId: string,
    foundId: string,
    description: string,
    date: string,
    amount: number,
    isActive: boolean,
    type: string
  }){
    this.httpClient.post(this.configService.getLocalConfig().api + "/v1/api/member/update-participation-status",item).subscribe((data:any)=>{
      this.toast.success("Contribution was updated", "Success");
      this.findParticipationByMemberAndAccount();
      this.searchParticipationByAccount(this.account.id, false);
    }, error => {
      item.isActive = !item.isActive;
    });
  }

  public getMemberAccountHistoricProfitByAccountAndMember(){
    this.memberAccountFloatingProfit = [];
    this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/member/get-member-account-historic-profit-by-account-and-member/"+this.account.id+"/"+this.selectMemberRow.memberId).subscribe((data:any)=>{
      this.memberAccountFloatingProfit = data;
    }, error => {

    });
  }

  public getRealProfitByAccountAndMember(){
    this.memberAccountRealProfit = [];
    this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/member/get-real-profit-by-account-and-member/"+this.account.id+"/"+this.selectMemberRow.memberId).subscribe((data:any)=>{
      this.memberAccountRealProfit = data;
    }, error => {

    });
  }

}
