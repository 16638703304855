import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {GraphModalService} from './graph-modal.service';
import {ConfigService} from '../../../services/config.service';
import {HttpClient} from '@angular/common/http';
import * as Highcharts from "highcharts/highstock";
import HIndicatorsAll from "highcharts/indicators/indicators-all";
import HDragPanes from "highcharts/modules/drag-panes";
import HAnnotationsAdvanced from "highcharts/modules/annotations-advanced";
import HPriceIndicator from "highcharts/modules/price-indicator"
import HAccessibility from 'highcharts/modules/accessibility';
import HStockTools from 'highcharts/modules/stock-tools';
import * as moment from 'moment';
import {GraphService} from '../../graph/graph.service';

HAccessibility(Highcharts);
HIndicatorsAll(Highcharts);
HDragPanes(Highcharts);
HAnnotationsAdvanced(Highcharts);
HPriceIndicator(Highcharts);
HStockTools(Highcharts);

@Component({
  selector: 'graph-modal',
  templateUrl: './graph-modal.component.html',
  styleUrls: ['./graph-modal.component.css']
})
export class GraphModalComponent implements OnInit, OnDestroy, AfterViewInit {

  Highcharts: typeof Highcharts = Highcharts;
  myChart:any = null;

  isFullscreen: boolean = false;
  showModal:boolean = false;
  @Input() title = "";
  @Input() icon = "fa-signal";
  @Input() instrumentCode:string = null;
  @Input() timing:string = 'DAILY';
  dataList:[] = [];
  dataRequestCompleted: boolean = false;

  //Subscription
  private showModalSubscription = new Subscription();
  private titleSubscription = new Subscription();
  private iconSubscription = new Subscription();
  private instrumentCodeSubscription = new Subscription();
  private timingSubscription = new Subscription();
  private drawChartSubscription = new Subscription();

  constructor(private configService: ConfigService,
              private httpClient: HttpClient,
              private graphModalService: GraphModalService,
              private graphService: GraphService) {

  }

  ngAfterViewInit() {
    this.showModalSubscription = this.graphModalService.showModal.subscribe((data:boolean)=>{
      this.showModal = data;
    });

    this.titleSubscription = this.graphModalService.title.subscribe((data:string)=>{
      this.title = data;
    });

    this.iconSubscription = this.graphModalService.icon.subscribe((data:string)=>{
      this.icon = data;
    });

    this.instrumentCodeSubscription = this.graphModalService.instrumentCode.subscribe((data:string)=>{
      this.instrumentCode = data;
    });

    this.timingSubscription = this.graphModalService.timing.subscribe((data:string)=>{
      this.timing = data;
    });

    this.drawChartSubscription = this.graphModalService.drawChart.subscribe((data:boolean)=>{
      if(data){
        let obj = setTimeout(()=>{
          this.showGraph();
          }, 250);
      }
    });
  }

  ngOnInit() {

  }

  public showGraph(){
    //Cleaning graph
    this.graphService.cleanGraph.emit();

    //Creating Graph
    let startDate = moment().add(-20,'year').format(this.configService.getLocalConfig().date.format);
    let endDate = moment().format(this.configService.getLocalConfig().date.format);

    if(this.timing == 'DAILY')
      this.timing = '_D';
    else if(this.timing == 'MONTH')
      this.timing = '_MONTH';
    else if(this.timing == '1MINUTE')
      this.timing = "_1M";
    else if(this.timing == 'WEEK')
      this.timing = '_W';
    else if(this.timing == 'YEAR')
      this.timing = '_MONTH';

    this.graphService.timing.emit(this.timing);
    this.graphService.startDate.emit(startDate);
    this.graphService.endDate.emit(endDate);
    this.graphService.ticket.emit(this.instrumentCode);
  }

  ngOnDestroy(): void {
    this.showModalSubscription.unsubscribe();
    this.titleSubscription.unsubscribe();
    this.iconSubscription.unsubscribe();
    this.instrumentCodeSubscription.unsubscribe();
    this.timingSubscription.unsubscribe();
  }

  onClose(){
    this.graphModalService.closeModal();
  }

}
