import {Directive, ElementRef, HostBinding, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[listGroupItemToggle]'
})
export class ListGroupItemToggleDirective{

  @HostBinding('class.active') show = false;

  @HostListener('mouseover')toggleOpen(){
    this.show = !this.show;
  }

  constructor(private el: ElementRef, private render: Renderer2) {

  }

  ngAfterViewInit(){

  }

}
