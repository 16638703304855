import {Component, Input, OnInit} from '@angular/core';
import HAccessibility from "highcharts/modules/accessibility";
import Highcharts3D from "highcharts/highcharts-3d";
import * as Highcharts from 'highcharts';

HAccessibility(Highcharts);
Highcharts3D(Highcharts);

@Component({
  selector: 'pie-graph',
  templateUrl: './pie-graph.component.html',
  styleUrls: ['./pie-graph.component.css']
})
export class PieGraphComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;

  @Input() show3D: boolean = true;
  @Input() data:any = null;
  @Input() tooltip:string = "";

  constructor() { }

  ngOnInit() {
    this.loadPieChartWallet();
  }

  public loadPieChartWallet(){
    let dataValue = [];

    if(this.data && this.data.length > 0){
      for(let key in this.data){
        let obj = {};
        for(let k in this.data[key]){
          obj[k] = this.data[key][k];
        }
        obj['sliced'] = false;
        obj['selected'] = true;
        dataValue.push(obj);
      }
    }

    let options:any = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        options3d: {
          enabled: this.show3D,
          alpha: 45,
          beta: 0
        }
      },
      title: {
        text: '',
        enabled: false
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },

      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          depth: 35,
          dataLabels: {
            enabled: false,
            format: '<b>{point.code}</b>: {point.percentage:.1f} %'
          }
        }
      },
      series: [{
        name: 'Participation',
        colorByPoint: true,
        data: dataValue
      }]
    };

    if(this.tooltip != ""){
      options.tooltip = {
        pointFormat: this.tooltip
      }
    }

    this.Highcharts.chart('containerWalletPieChart',options);
  }

}
