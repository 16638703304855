import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class GraphModalService{

  showModal = new EventEmitter<boolean>();
  drawChart = new EventEmitter<Boolean>();
  isOpen = false;
  title = new EventEmitter<string>();
  icon = new EventEmitter<string>();
  instrumentCode = new EventEmitter<string>();
  timing = new EventEmitter<string>();

  public openModal(title:string = '', icon:string = 'fa-signal', instrumentCode:string = null, timing:string = 'MONTH'){
    this.isOpen = true;
    this.title.emit(title);
    this.icon.emit(icon);
    this.instrumentCode.emit(instrumentCode);
    this.timing.emit(timing);
    this.showModal.emit(true);
    this.drawChart.emit(true);
  }

  public closeModal(){
    this.isOpen = false;
    this.showModal.emit(false);
  }

  public isOpenModal(){
    return this.isOpen;
  }

}
