import {EventEmitter, Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class AlertNotificationService {

  public showNotificationMessage = new EventEmitter<string>();
  public showErrorMessage = new EventEmitter<string>();
  public showWarningMessage = new EventEmitter<string>();

  constructor() {}
}
