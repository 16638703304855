import {Component, Input, OnInit} from '@angular/core';
import HAccessibility from "highcharts/modules/accessibility";
import {AccountService} from '../../account/account.service';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';;
import * as moment from "moment";
import * as Highcharts from "highcharts/highstock";
import HIndicatorsAll from "highcharts/indicators/indicators-all";
import HDragPanes from "highcharts/modules/drag-panes";
import HAnnotationsAdvanced from "highcharts/modules/annotations-advanced";
import HPriceIndicator from "highcharts/modules/price-indicator";
import HStockTools from "highcharts/modules/stock-tools";

HAccessibility(Highcharts);
HIndicatorsAll(Highcharts);
HDragPanes(Highcharts);
HAnnotationsAdvanced(Highcharts);
HPriceIndicator(Highcharts);
HStockTools(Highcharts);

@Component({
  selector: 'simple-historic-graph',
  templateUrl: './simple-historic-graph.component.html',
  styleUrls: ['./simple-historic-graph.component.css']
})
export class SimpleHistoricGraphComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  myChart:any = null;
  @Input() graphHeight:any = '50%';
  @Input() title:string = "";
  @Input() subTitle:string = "";
  @Input() yAxisTitle:string = "";
  @Input() seriesName = "";
  @Input() seriesType = "area";
  @Input() data = [];
  @Input() showGUI: boolean = false;
  @Input() stockToolButtons = [];
  @Input() scrollBar: boolean = false;
  @Input() scale:string = "logarithmic";

  constructor(private accountService: AccountService,
              private httpClient: HttpClient,
              private configService: ConfigService) { }

  ngOnInit() {
    this.loadGraph();
  }

  public loadGraph(){

    let options:any = {
      title: {
        text: this.title
      },
      stockTools: {
        gui: {
          enabled: this.showGUI,
          buttons : this.stockToolButtons
        }
      },
      subtitle: {
        text: this.subTitle
      },
      legend: {
        enabled: false
      },
      rangeSelector: {
        buttons: [{
          type: 'hour',
          count: 1,
          text: '1h'
        },
        {
          type: 'day',
          count: 1,
          text: '1D'
        },
        {
          type: 'week',
          count: 1,
          text: '1W'
        },
        {
          type: 'month',
          count: 1,
          text: '1M'
        },
        {
          type: 'year',
          count: 1,
          text: '1Y'
        },
        {
          type: 'year',
          count: 2,
          text: '2Y'
        },
        {
          type: 'year',
          count: 3,
          text: '3Y'
        },
        {
          type: 'ytd',
          text: 'YTD'
        },
        {
          type: 'all',
          count: 1,
          text: 'All'
        }],
        selected: 7,
        inputEnabled: false
      },
      zoomType: 'x',
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis:[{
        title: {
          text: this.yAxisTitle
        },
        labels: {
          align: 'left'
        },
        height: '100%',
        crosshair: {
          snap: false,
          label: {
            enabled: true,
            format: '{value:.2f}'
          }
        },
        resize: {
          enabled: true
        }
      }],
      scrollbar: {
        enabled: this.scrollBar
      },
      tooltip: {
        shape: 'square',
        headerShape: 'callout',
        borderWidth: 0,
        shadow: true,
        positioner: function (width, height, point) {
          let chart = this.chart,
            position;

          if (point.isHeader) {
            position = {
              x: Math.max(
                // Left side limit
                chart.plotLeft,
                Math.min(
                  point.plotX + chart.plotLeft - width / 2,
                  // Right side limit
                  chart.chartWidth - width - chart.marginRight
                )
              ),
              y: (point.plotY).toFixed(2)
            };
          } else {
            position = {
              x: point.series.chart.plotLeft,
              y: (point.series.yAxis.top - chart.plotTop).toFixed(2)
            };
          }
          return position;
        }
      },
      series: [{
        name: this.seriesName,
        type: this.seriesType,
        data: this.data
      }]
    };

    this.myChart = this.Highcharts.stockChart('containerWalletHistoric',options);
  }

}
