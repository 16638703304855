import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../services/config.service';
import {SpinnerService} from '../../services/spinner.service';
import {ToastrService} from 'ngx-toastr';
import {ModalConfirmationService} from '../common/modal-confirmation/modal-confirmation.service';
import {Subscription} from 'rxjs';
import {SecurityService} from '../../services/security.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {isNull} from 'util';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {

  wallet: any = [];
  currency: any = [];
  newAccount: {name:string, currency: string, initialBalance:number, isActive:boolean, isDefault:boolean, code:string, token:string} = {name:"",currency:"", initialBalance:0, isActive:false, isDefault:false, code:null, token:null};
  creatingNewAccount:boolean = false;

  constructor(private httpClient: HttpClient,
              private configService: ConfigService,
              private spinnerService: SpinnerService,
              private toast: ToastrService,
              private modalConfimationService: ModalConfirmationService,
              public securityService: SecurityService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    if(this.securityService.checkPermissionByNameKey('VMWt')){
      this.findCurrency();
    }
  }

  public findCurrency(){
    this.httpClient.get(this.configService.getLocalConfig().api + "/v1/api/currency/find-all").subscribe((data)=>{
      this.currency = data;
    }, error => {

    });
  }
}
