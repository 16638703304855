import {Directive, ElementRef, HostBinding, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[collapseLeftMenu]'
})
export class LeftMenuDirective{
  private isCollapse = true;

  @HostListener('click')toggleOpen(){
    this.isCollapse = !this.isCollapse;
    this.ngAfterViewInit();
  }

  constructor(private el: ElementRef, private render: Renderer2) {

  }

  ngAfterViewInit(){
    //Processing ul elements
    let liParent = this.el.nativeElement.offsetParent;
    let ul = this.el.nativeElement.offsetParent.querySelector('ul');

    (this.isCollapse)?this.render.removeClass(liParent, 'active'):this.render.addClass(liParent, 'active');
    (this.isCollapse)?this.render.removeClass(ul, 'in'):this.render.addClass(ul, 'in');
  }

}
