import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { AppComponent } from './app.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { BodyComponent } from './body/body.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChartComponent } from './chart/chart.component';
import { FormsModule } from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { ScreenerComponent } from './screener/screener.component';
import { NouisliderModule } from 'ng2-nouislider';
import {RoutingModule} from '../module/routing-module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ReportComponent } from './report/report.component';
import {ConfigService} from '../services/config.service';
import { WatchlistComponent } from './watchlist/watchlist.component';
import { ToolsComponent } from './tools/tools.component';
import { StockCalculatorComponent } from './tools/stock-calculator/stock-calculator.component';
import {DropdownDirective} from '../directive/dropdown.directive';
import {LeftMenuDirective} from '../directive/leftmenu.directive';
import { StockCalculatorAdvComponent } from './tools/stock-calculator-adv/stock-calculator-adv.component';
import { GraphComponent } from './graph/graph.component';
import {GraphService} from './graph/graph.service';
import {NgxSpinnerModule} from 'ngx-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NumberDirective} from '../directive/number.directive';
import { BootstrapMessageComponent } from './common/bootstrap-message/bootstrap-message.component';
import { AuthComponent } from './auth/auth.component';
import {RecaptchaModule} from 'ng-recaptcha';
import { RegisterComponent } from './register/register.component';
import {LeftCollapseLeftmenuDirective} from '../directive/left-collapse-leftmenu.directive';
import { AlertComponent } from './alert/alert.component';
import { NotificationComponent } from './notification/notification.component';
import { UserComponent } from './user/user.component';
import { GroupComponent } from './group/group.component';
import { TaskComponent } from './task/task.component';
import { AlertNotificationComponent } from './common/alert-notification/alert-notification.component';
import {AlertNotificationDirective} from './common/alert-notification/alert-notification.directive';
import {ToastrModule} from 'ngx-toastr';
import {CustomHttpInterceptor} from './custom-http-interceptor';
import { CompanySearchComponent } from './common/company-search/company-search.component';
import {EllipsisPipe} from '../pipes/ellipsis.pipe';
import {NgbModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ListGroupItemToggleDirective} from '../directive/listGroupItemToggle.directive';
import { OpportunitySeekerComponent } from './tools/opportunity-seeker/opportunity-seeker.component';
import { TableComponent } from './common/table/table.component';
import { ModalComponent } from './common/modal/modal.component';
import { MultiselectComponent } from './common/multiselect/multiselect.component';
import {FilterPipe} from '../pipes/filter.pipe';
import { InputInlineComponent } from './common/input-inline/input-inline.component';
import { ToggleComponent } from './common/toggle/toggle.component';
import { ModalConfirmationComponent } from './common/modal-confirmation/modal-confirmation.component';
import { WalletComponent } from './wallet/wallet.component';
import { AccountComponent } from './account/account.component';
import {NullValuePipe} from '../pipes/nullValue.pipe';
import {DateTimePipe} from '../pipes/dateTime.pipe';
import { WalletPieChartComponent } from './dashboard/wallet-pie-chart/wallet-pie-chart.component';
import { WalletHistoricComponent } from './dashboard/wallet-historic/wallet-historic.component';
import { InstrumentComparatorComponent } from './tools/instrument-comparator/instrument-comparator.component';
import { InstrumentComparatorGraphComponent } from './tools/instrument-comparator/instrument-comparator-graph/instrument-comparator-graph.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TransactionComponent } from './transaction/transaction.component';
import { OperationComponent } from './operation/operation.component';
import { OrderComponent } from './order/order.component';
import {RemoveLodashPipe} from '../pipes/removeLodash.pipe';
import { MemberComponent } from './contribution/member/member.component';
import { AccountMappingComponent } from './contribution/account-mapping/account-mapping.component';
import { AddUpdateMemberModalComponent } from './contribution/member/add-update-member-modal/add-update-member-modal.component';
import { ParticipationModalComponent } from './contribution/account-mapping/participation-modal/participation-modal.component';
import { SearchMemberAutocompleteComponent } from './contribution/member/search-member-autocomplete/search-member-autocomplete.component';
import { PieGraphComponent } from './common/pie-graph/pie-graph.component';
import { SimpleSpinnerComponent } from './common/simple-spinner/simple-spinner.component';
import { SimpleHistoricGraphComponent } from './common/simple-historic-graph/simple-historic-graph.component';
import { TopWinnerComponent } from './dashboard/top-winner/top-winner.component';
import { TopLoserComponent } from './dashboard/top-loser/top-loser.component';
import { GraphModalComponent } from './common/graph-modal/graph-modal.component';
import { NewIpoComponent } from './dashboard/new-ipo/new-ipo.component';
import { NewHighComponent } from './dashboard/new-high/new-high.component';
import { NewLowComponent } from './dashboard/new-low/new-low.component';
import { OptionManagementComponent } from './option/option-management/option-management.component';
import {
  InjectableRxStompConfig,
  RxStompService,
  rxStompServiceFactory,
} from '@stomp/ng2-stompjs';
import {myRxStompConfig} from './my-rx-stomp.config';
import { OpportunitySeekerFilterComponent } from './tools/opportunity-seeker/opportunity-seeker-filter/opportunity-seeker-filter.component';
import { BubbleTagComponent } from './common/bubble-tag/bubble-tag.component';
import { StaticReportComponent } from './report/static-report/static-report.component';
import { DynamicReportComponent } from './report/dynamic-report/dynamic-report.component';
import { StaticReportFilterComponent } from './report/static-report/static-report-filter/static-report-filter.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { EmailAddressModalComponent } from './report/email-address-modal/email-address-modal.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BigNumberPipe} from '../pipes/bigNumber.pipe';
import { SearchInstrumentWithBubbleTagComponent } from './common/search-instrument-with-bubble-tag/search-instrument-with-bubble-tag.component';
import { EmptyComponent } from './empty/empty.component';


@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    BodyComponent,
    FooterComponent,
    DashboardComponent,
    ChartComponent,
    ScreenerComponent,
    PageNotFoundComponent,
    ReportComponent,
    WatchlistComponent,
    ToolsComponent,
    StockCalculatorComponent,
    DropdownDirective,
    LeftMenuDirective,
    StockCalculatorAdvComponent,
    GraphComponent,
    NumberDirective,
    BootstrapMessageComponent,
    AuthComponent,
    RegisterComponent,
    LeftCollapseLeftmenuDirective,
    AlertComponent,
    NotificationComponent,
    UserComponent,
    GroupComponent,
    TaskComponent,
    AlertNotificationComponent,
    AlertNotificationDirective,
    CompanySearchComponent,
    EllipsisPipe,
    FilterPipe,
    NullValuePipe,
    BigNumberPipe,
    DateTimePipe,
    RemoveLodashPipe,
    ListGroupItemToggleDirective,
    OpportunitySeekerComponent,
    TableComponent,
    ModalComponent,
    MultiselectComponent,
    InputInlineComponent,
    ToggleComponent,
    ModalConfirmationComponent,
    WalletComponent,
    AccountComponent,
    WalletPieChartComponent,
    WalletHistoricComponent,
    InstrumentComparatorComponent,
    InstrumentComparatorGraphComponent,
    TransactionComponent,
    OperationComponent,
    OrderComponent,
    MemberComponent,
    AccountMappingComponent,
    AddUpdateMemberModalComponent,
    ParticipationModalComponent,
    SearchMemberAutocompleteComponent,
    PieGraphComponent,
    SimpleSpinnerComponent,
    SimpleHistoricGraphComponent,
    TopWinnerComponent,
    TopLoserComponent,
    GraphModalComponent,
    NewIpoComponent,
    NewHighComponent,
    NewLowComponent,
    OptionManagementComponent,
    OpportunitySeekerFilterComponent,
    BubbleTagComponent,
    StaticReportComponent,
    DynamicReportComponent,
    StaticReportFilterComponent,
    EmailAddressModalComponent,
    SearchInstrumentWithBubbleTagComponent,
    EmptyComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NouisliderModule,
    RoutingModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    RecaptchaModule,
    NgbModule,
    NgbTooltipModule,
    FontAwesomeModule,
    ToastrModule.forRoot({
      progressBar: true,
      progressAnimation: 'increasing',
      positionClass: 'toast-top-right'
    }),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot()
  ],
  providers: [
    {
      provide: InjectableRxStompConfig,
      useValue: myRxStompConfig,
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig],
    },
    ConfigService,
    GraphService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
