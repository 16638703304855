import * as moment from 'moment';

export class User {

  private _id?:string;
  private _firstName?: string;
  private _lastName?: string;
  private _password?: string;
  private _email?:string;
  private _phoneNumber?:string;
  private _isSystem?:boolean;
  private _token?:string;
  private _tokenExpire?:string;
  private _permissions?:[];

  constructor() {}

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get password(): string {
    return this._password;
  }

  set password(value: string) {
    this._password = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get phoneNumber(): string {
    return this._phoneNumber;
  }

  set phoneNumber(value: string) {
    this._phoneNumber = value;
  }

  get isSystem(): boolean {
    return this._isSystem;
  }

  set isSystem(value: boolean) {
    this._isSystem = value;
  }

  get token(): string {
    if(!this._tokenExpire || (moment() > moment(this._tokenExpire))){
      return null;
    }
    return this._token;
  }

  set token(value: string) {
    this._token = value;
  }

  get tokenExpire(): string {
    return this._tokenExpire;
  }

  set tokenExpire(value: string) {
    this._tokenExpire = value;
  }

  get permissions(): [] {
    return this._permissions;
  }

  set permissions(value: []) {
    this._permissions = value;
  }

}
