import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalConfirmationService{

  questionEvent = new EventEmitter<string>();
  showModal = new EventEmitter<boolean>();
  response = new EventEmitter<boolean>();

  onOpen(question){
    this.questionEvent.emit(question);
    this.showModal.emit(true);
    return this.response;
  }
}
