import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompanySearchService {

  private _searchCriteria: {code: string, timing: string, startDate: string, endDate: string} = null;

  constructor() {}


  get searchCriteria(): {code: string, timing: string, startDate: string, endDate: string} {
    return this._searchCriteria;
  }

  set searchCriteria(value: {code: string, timing: string, startDate: string, endDate: string}) {
    this._searchCriteria = value;
  }
}
