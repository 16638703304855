import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.css']
})
export class ToggleComponent implements OnInit {

  @Input() onLabel: string = "On";
  @Input() offLabel: string = "Off";
  @Input() buttonSize: string = "";
  @Input()  value: boolean = true;
  @Output() valueChange = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
