import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-screener',
  templateUrl: './screener.component.html',
  styleUrls: ['./screener.component.css']
})
export class ScreenerComponent implements OnInit {
  someRange = [ 2, 10 ];
  singleRange = [ 2 ];

  constructor() { }

  ngOnInit() {
  }

}
